import React from 'react';
import './home.css';
import { mobileBreakpoint } from '../../const';
import TotalCounter from '../../components/total -counter/total-counter';
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import MainMenu from '../../components/main-menu/main-menu';
import userIcon from '../../assets/user-icon.png';
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg';

export class Home extends React.Component {
  componentDidMount() {
    ReactGA.set({ page: "/" });
    ReactGA.pageview("/");
    this.props.hideHomeIcon();
  }

  componentWillMount() {
    this.props.showTotalCounter();
  }

  render() {
    return (
      <div>
        { this.props.windowSize.width > mobileBreakpoint ? <TotalCounter allTime={ this.props.total } yesterday={ this.props.yesterday } toggleSound={ this.props.toggleSound } isSoundOn={ this.props.isSoundOn } /> : null }
 
        <div className={ this.props.windowSize.width > mobileBreakpoint ? "game-menu" : "game-menu game-menu-mobile" }>
          {
            !(this.props.windowSize.width > mobileBreakpoint) ?
            <>
              <div
                className="signin-button"
                onClick={ () => {
                  this.props.history.push(`${this.props.user ? "/my-account" : "/auth"}`);
                  if (this.props.isSoundOn) this.props.buttonSound.play();
                }}
              >
                <img src={ userIcon } alt="user icon" />
                <p>{ !this.props.user ? "SIGN IN / SIGN UP" : "Welcome, " + this.props.user.username }</p>
              </div>
              <div
                className="view-leaderboard-button"
                onClick={ () => {
                  this.props.history.push("/leaderboard");
                  if (this.props.isSoundOn) this.props.buttonSound.play();
                }}
              >
                <PlusIcon />
                <p><span className="orange">VIEW</span> LEADERBOARD</p>
              </div>
            </>
            : null
          }
          <div className="featured-header">
            {
              this.props.windowSize.width > mobileBreakpoint ?
              <p>Pick a Game, Play & Donate.</p>
             :
              <p>pick a game</p>
            }
          </div>
          <MainMenu isMobile={ !(this.props.windowSize.width > mobileBreakpoint) } history={ this.props.history } buttonSound={ this.props.buttonSound } isSoundOn={ this.props.isSoundOn } />
        </div>
      </div>
    );
  };
}
  
export default withRouter(Home);