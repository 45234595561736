import React from 'react';
import taglineDesktop from '../../assets/tagline-desktop.png';
import taglineMobile from '../../assets/tagline-mobile.png';
import { mobileBreakpoint } from '../../const';
import { withRouter } from 'react-router-dom';
import userIcon from '../../assets/user-icon.png';
import ReactGA from 'react-ga';
import lockIcon from '../../assets/lock-icon.png';
import MobileExitIcon from '../../components/mobile-exit-icon/mobile-exit-icon';

const _emailStep = "emailStep";
const _passwordStep = "passwordStep";

export class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      resetCode: "",
      currentStep: _emailStep,
      isSubmitting: false,
      successMessage: "",
      error: ""
    };
  }

  componentDidMount() {
    ReactGA.set({ page: "/password-reset" });
    ReactGA.pageview("/password-reset");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    this.props.setIsLoading(false);
  }

  handleSendResetCode() {
    if(this.props.isSoundOn) this.props.buttonSound.play();
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)) {
      this.setState({ error: "Email is not valid." });
    } else if (this.state.email && !this.state.isSubmitting) {
      this.setState({ isSubmitting: true, error: "" });
      fetch("/api/request-password-reset", {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          Email: this.state.email,
        })
      })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.setState({ isSubmitting: false, currentStep: _passwordStep });
        } else {
          this.setState({ error: result.error, isSubmitting: false });
        }
      })
      .catch(result => this.setState({ error: result.error ? result.error : "Unexpected error has occurred. Please try again later.", isSubmitting: false }));
    }
  }

  handlePasswordReset() {
    if(this.props.isSoundOn) this.props.buttonSound.play();
    if (!this.state.resetCode) {
      this.setState({ error: "Reset code is a required field." });
    } else if (!this.state.password) {
      this.setState({ error: "Password is a required field." });
    } else if (!this.state.confirmPassword) {
      this.setState({ error: "Confirm password is a required field." });
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ error: "Passwords do not match." });
    } else if (this.state.password.length < 6) {
      this.setState({ error: "Passwords must be at least 6 characters long." });
    } else if (!this.state.isSubmitting) {
      this.setState({ isSubmitting: true, error: "" });
      fetch("/api/reset-password", {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          Email: this.state.email,
          NewPassword: this.state.password,
          ResetCode: this.state.resetCode
        })
      })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.setState({ isSubmitting: false, successMessage: "You have successfully reset your password." });
        } else {
          this.setState({ error: result.error, isSubmitting: false });
        }
      })
      .catch(result => this.setState({ error: result.error ? result.error : "Unexpected error has occurred. Please try again later.", isSubmitting: false }));
    }
  }

  render() {
    return (
      <div>
        {
          this.props.windowSize.width > mobileBreakpoint ?
            <div className={ this.props.windowSize.width > mobileBreakpoint ? "tagline" : "tagline mobile" }>
              <img src={ this.props.windowSize.width > mobileBreakpoint ? taglineDesktop : taglineMobile } alt="Play with purpose - donate water with every correct answer!" />
            </div>
          : null
        }
        <div className={ `auth-container ${this.props.isMobile ? "auth-container-mobile" : ""}` } style={{ minHeight: `${this.props.minHeight*0.80}px` }}>
          <MobileExitIcon style={{ paddingRight: "1em", paddingTop: "1em" }} history={ this.props.history } />
          <div className="auth-content">
            <h1>Password Reset</h1>
            <p>Forgot password? Follow steps below.</p>
            <hr />
            {
              this.state.currentStep === _emailStep ?
              <>
                <br />
                <div className="input">
                  <div className="image-wrap" style={{ backgroundImage: `url(${userIcon})` }}></div>
                  <input placeholder="Enter your email" autoFocus value={ this.state.email } onChange={ e => this.setState({ email: e.target.value }) } />
                </div>

                <p className="auth-error">{ this.state.error }</p>

                <br />
                <div className="log-in-button" onClick={ () => this.handleSendResetCode() }>
                  <p>Send reset code</p>
                </div>
              </>
            :
              <>
                <br />
                <p>A password reset code has been sent to your email address.<br />Please enter it below.</p>
                <br />
                <div className="input">
                  <input placeholder="Password reset code" autoFocus value={ this.state.resetCode } onChange={ e => this.setState({ resetCode: e.target.value }) } style={{ paddingLeft: "2em" }} />
                </div>
                <div className="input">
                  <div className="image-wrap lock" style={{ backgroundImage: `url(${lockIcon})` }}></div>
                  <input placeholder="Password" type="password" value={ this.state.password } onChange={ e => this.setState({ password: e.target.value }) } autoComplete="new-password" />
                </div>
                <div className="input">
                  <div className="image-wrap lock" style={{ backgroundImage: `url(${lockIcon})` }}></div>
                  <input placeholder="Confirm password" type="password" value={ this.state.confirmPassword } onChange={ e => this.setState({ confirmPassword: e.target.value }) } />
                </div>
                { this.state.error ? <p className="auth-error">{ this.state.error }</p> : null}
                { this.state.successMessage ? <p className="success-message">{ this.state.successMessage }</p> : null}
                <br />
                <div className="log-in-button" onClick={ () => this.handlePasswordReset() }>
                  <p>Reset Password</p>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(PasswordReset);
