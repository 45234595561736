import React from 'react';
import './cookies-popup.css';
import gameWellCircleLogo from '../../assets/game-well-circle.png';
import generalWaterImg from '../../assets/general-water-img.jpg';
import charityWaterLogo from '../../assets/charity-water-logo.png';

export const CookiesPopup = props => {
  return(
    <div className="cookies-popup-bg">
      <div className="cookies-popup">
        {
          !props.isMobile ?
          <>
            <div className="cookies-popup-header">
              <img src={ gameWellCircleLogo } alt="Game Well Logo" />
              <img className="cookies-popup-main-img" src={ generalWaterImg } alt="Charity" />
              <img src={ charityWaterLogo } alt="Charity Water Logo" />
            </div>
            <br /><br />

            <h2><b>Access to clean water is a right all citizens of the world should have.</b></h2><br /><br />

            <p className="cookies-popup-main-text">TheGameWell has partnered with CharityWater.org to provide drinking water to developing nations. <span className="cookies-popup-emphased">Each time you play and answer a clue correctly, we donate the equivalent
                of 5 cups of water</span> to CharityWater.</p>
            
            <br /><br />
            <button className="dismiss" onClick={ props.onDismiss }>Ok Let's Play!</button>
            <br /><br />
            <p className="cookies-notice">This website uses cookies and other site data to ensure you get the best experience.<br />The only tracking on the site is via Google Analytics.</p>
            <br /><br />
          </>
          :
          <>
            <img className="cookies-popup-mobile-img" src={ gameWellCircleLogo } alt="Game Well Logo" />
            <h2 className="cookies-popup-h2-mobile"><b>Access to clean water is a right all citizens of the world should have.</b></h2>
            <img className="cookies-popup-mobile-main-img" src={ generalWaterImg } alt="Charity" />

            <p className="cookies-popup-main-text-mobile">TheGameWell has partnered with CharityWater.org to provide drinking water to developing nations.</p>
            <p><span className="cookies-popup-emphased">Each time you play and answer a clue correctly, we donate the equivalent
                of 5 cups of water</span> to CharityWater.</p>
            
            <button className="dismiss" onClick={ props.onDismiss }>Ok Let's Play!</button>
            <br />
            <p className="cookies-notice-mobile">This website uses cookies and other site data to ensure you get the best experience. The only tracking on the site is via Google Analytics.</p>
          </>
        }
      </div>
    </div>
  );
}

export default CookiesPopup;