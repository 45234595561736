import React from 'react';
import './slider.css';
import ReactSlider from 'react-slider';

export const Slider = props => {
  return(
    <div className="slider-wrap">
      <div className="value-box">
        { !props.allowPreYear ? <p>{ props.value[0] }</p> : props.value[0] < props.min ? <p className="preYear">PRE<br/>{ props.min }</p> : <p>{ props.value[0] }</p> }
      </div>
      <div className="react-slider-wrap">
        <ReactSlider
          className="react-slider"
          thumbClassName="slider-thumb"
          trackClassName="slider-track"
          min={ props.allowPreYear ? props.min - 1 : props.min }
          max={ props.max }
          value={ props.value }
          pearling={ false }
          minDistance={ props.minRange }
          onChange={ props.onChange }
          disabled={ props.disabled ? true : false }
        />
      </div>
      <div className="value-box">
        <p>{ props.value[1] }</p>
      </div>
    </div>
  );
}

export default Slider;