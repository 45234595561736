import React from 'react';
import './sound-toggle.css';
import { ReactComponent as SoundOnIcon } from '../../assets/sound-on.svg';
import { ReactComponent as SoundOffIcon } from '../../assets/sound-off.svg';

export const SoundToggle = props => {
  return(
    <div className="sound-toggle" onClick={ props.toggleSound } style={ props.style }>
      { props.isSoundOn ? <SoundOnIcon /> : <SoundOffIcon /> }
      <p>SOUND: { props.isSoundOn ? "ON" : "OFF" }</p>
    </div>
  );
}

export default SoundToggle;