import React from 'react';
import './sports.css';
import Tagline from '../../components/tagline/tagline';
import NoScrollPanel from '../../components/no-scroll-panel/no-scroll-panel';
import GamePanel from '../../components/game-panel/game-panel';
import { shuffle, parseCvsAsync, postData } from '../../components/helpers/helpers';
import { nextQuestionTimeout, mobileBreakpoint } from '../../const';
import MobileHomeIcon from '../../components/mobile-home-icon/mobile-home-icon';
import PlayButton from '../../components/play-button/play-button';
import sportsQuestions from '../../assets/sports-questions.csv';
import Slider from '../../components/slider/slider';
import ReactGA from 'react-ga';
import CorrectAnswerSound from '../../assets/correct-answer-sound.mp3';
import WrongAnswerSound from '../../assets/wrong-answer-sound.mp3';

const football = "Football";
const basketball = "Basketball";
const baseball = "Baseball";
const hockey = "Hockey";
const soccer = "Soccer";
const all = "_all";

export default class Sports extends React.Component {
  sportsMenuWrapElement;
  correctAnswerSound;
  wrongAnswerSound;

  constructor(props) {
    super(props);

    this.state = {
      sportsMenuWrapHeight: 0,
      selectedSports: [],
      selectedYears: [1978, 2020],
      startGame: false,
      isAnswered: false,
      questions: null,
      loadNextImage: false,
      backImage: null,
      usedQuestions: [],
      question: {
        value: "",
        beforeImage: "",
        afterImage: "",
        answers: [],
        correct: false,
        selected: ""
      },
      nextQuestion: {
        value: "",
        beforeImage: "",
        afterImage: "",
        answers: [],
        correct: false,
        selected: ""
      },
      score: 0
    }
  }

  componentDidMount() {
    ReactGA.set({ page: "/sports" });
    ReactGA.pageview("/sports");
    this.setState({ sportsMenuWrapHeight: this.sportsMenuWrapElement.clientHeight });
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    if(localStorage.getItem("sports-questions")) {
      localStorage.removeItem("sports-questions");
    }
    if(localStorage.getItem("sports-questions_v2")) {
      this.setState({
        questions: JSON.parse(localStorage.getItem("sports-questions_v2"))
      });
    } else {
      parseCvsAsync(sportsQuestions).then(questions => {
        try {
          localStorage.setItem("sports-questions_v2", JSON.stringify(questions));
        } catch {}
        finally {
          this.setState({
            questions: questions
          });
        }
      });
    }
    this.correctAnswerSound = new Audio(CorrectAnswerSound);
    this.wrongAnswerSound = new Audio(WrongAnswerSound);

    this.props.setIsLoading(false);
  }

  toggleSelectedMode(event) {
    if(this.state.selectedSports.includes(event.target.id)) {
      let slecetedGameMods = [...this.state.selectedSports];
      slecetedGameMods.splice(slecetedGameMods.findIndex(el => el === event.target.id), 1);

      if(slecetedGameMods.includes(soccer) && slecetedGameMods.length === 1) {
        this.setState({
          selectedSports: [...slecetedGameMods],
          selectedYears: [this.state.selectedYears[0], this.state.selectedYears[1] < 2004 ? 2004 : this.state.selectedYears[1]]
        });
      } else {
        this.setState({
          selectedSports: [...slecetedGameMods]
        });
      }
    } else {
      this.setState({
        selectedSports: [
          ...this.state.selectedSports,
          event.target.id
        ],
        selectedYears: event.target.id === soccer && this.state.selectedSports.length === 0 ? [this.state.selectedYears[0], this.state.selectedYears[1] < 2004 ? 2004 : this.state.selectedYears[1]] : this.state.selectedYears
      });
    }
  }

  updateSelectedYears(value) {
    if ((!this.state.selectedSports.includes(soccer) || this.state.selectedSports.length > 1) || value[1] >= 2004) {
      this.setState({ selectedYears: value });
    } else {
      this.setState({ selectedYears: [value[0], 2004] });
    }
  }

  startGame() {
    const filtered = this.state.questions?.filter(q => 
      (
        (this.state.selectedSports.includes(football) && q.Sport === football)
        || (this.state.selectedSports.includes(basketball) && q.Sport === basketball)
        || (this.state.selectedSports.includes(baseball) && q.Sport === baseball)
        || (this.state.selectedSports.includes(hockey) && q.Sport === hockey)
        || (this.state.selectedSports.includes(soccer) && q.Sport === soccer)
      )
      &&
      (
        q.Year >= this.state.selectedYears[0]
        && q.Year <= this.state.selectedYears[1]
      )
    );
    this.setState({
      startGame: true,
      questions: [...filtered]
    }, () => this.getNextQuestion(() => this.loadNextQuestion()));
  }

  getNextQuestion(loadNextQuestion) {
    const noOfQs = this.state.questions.length;
    let chosenQ;
    do {
      chosenQ = Math.floor(Math.random() * noOfQs);
    } while(this.state.usedQuestions.includes(chosenQ));

    let filteredForAnswers = [];
    if(this.state.questions[chosenQ].Sport === soccer) {
      filteredForAnswers = this.state.questions
        .filter(q => q.Sport === this.state.questions[chosenQ].Sport && q.Year === this.state.questions[chosenQ].Year && q.Team === this.state.questions[chosenQ].Team);
    } else {
      filteredForAnswers = this.state.questions
        .filter(q => q.Sport === this.state.questions[chosenQ].Sport && q.Year === this.state.questions[chosenQ].Year);
    }
    let ans1;
    let ans2;
    let ans3;
    do {
      ans1 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans1].PlayerName === this.state.questions[chosenQ].PlayerName);
    do {
      ans2 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans2].PlayerName === this.state.questions[chosenQ].PlayerName
            || filteredForAnswers[ans2].PlayerName === filteredForAnswers[ans1].PlayerName);
    do {
      ans3 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans3].PlayerName === this.state.questions[chosenQ].PlayerName
      || filteredForAnswers[ans3].PlayerName === filteredForAnswers[ans2].PlayerName
      || filteredForAnswers[ans3].PlayerName === filteredForAnswers[ans1].PlayerName);

    const answers = [
      this.state.questions[chosenQ].PlayerName,
      filteredForAnswers[ans1].PlayerName,
      filteredForAnswers[ans2].PlayerName,
      filteredForAnswers[ans3].PlayerName
    ];

    const beforeImage = new Image();
    beforeImage.src = `/imgs/sports/before/${this.state.questions[chosenQ].BeforeImage}`;

    const afterImage = new Image();
    afterImage.src = `/imgs/sports/after/${this.state.questions[chosenQ].AfterImage}`;
    
    this.setState({
      nextQuestion: {
        value: this.state.questions[chosenQ].PlayerName,
        beforeImage: beforeImage,
        afterImage: afterImage,
        beforeImageName: this.state.questions[chosenQ].BeforeImage,
        afterImageName: this.state.questions[chosenQ].AfterImage,
        answers: shuffle(answers),
        correct: false,
        selected: ""
      },
      usedQuestions: this.state.usedQuestions.length < this.state.questions.length - 1 ? [
        ...this.state.usedQuestions,
        chosenQ
      ] : [chosenQ]
    }, () => {
      if(loadNextQuestion) loadNextQuestion();
    });
  }

  loadNextQuestion() {
    this.setState({
      question: {
        ...this.state.nextQuestion
      }
    });
  }

  checkAnswer(event) {
    const isAnsweredCorrectlly = this.state.question.value === event.target.id;
    this.getNextQuestion();

    if(isAnsweredCorrectlly) {
      if(this.props.isSoundOn) this.correctAnswerSound.play();
      this.props.addWaterDonated(5);
      this.setState({ score: this.state.score + 1 });
      ReactGA.event({
        category: 'Sports',
        action: 'Answered correctly'
      });
    } else {
      if(this.props.isSoundOn) this.wrongAnswerSound.play();
      this.props.addQuestionAnswered();
      ReactGA.event({
        category: 'Sports',
        action: 'Answered incorrectly'
      });
    }

    postData('/api/addanswer', {
      UserID: this.props.userId,
      Category: "sports",
      BeforeImage: this.state.question.beforeImageName,
      AfterImage: this.state.question.afterImageName,
      IsCorrect: isAnsweredCorrectlly ? 1 : 0
    });

    this.setState({
      backImage: this.state.question.afterImage.src,
      question: {
        ...this.state.question,
        selected: event.target.id,
        correct: this.state.question.value
      }
    }, () => {
      setTimeout(() => {
        this.setState({
          isAnswered: true
        });
      }, 50);
    });

    setTimeout(() => {
      this.setState({ loadNextImage: true });
    }, 250);
    
    setTimeout(() => {
      this.loadNextQuestion();
      this.setState({ isAnswered: false, loadNextImage: false });
    }, nextQuestionTimeout * 1000);
  }

  toggleAll() {
    if(this.state.selectedSports.length === 5) {
      this.setState({
        selectedSports: []
      });
    } else {
      this.setState({
        selectedSports: [football, basketball, baseball, hockey, soccer]
      });
    }
  }

  clearScore() {
    this.setState({ score: 0 });
  }

  render() {
    return(
      <div className="sports">
        {
          this.props.windowSize.width < mobileBreakpoint ? <MobileHomeIcon history={ this.props.history } /> : null
        }
        { 
          this.state.startGame
          ? 
          <div>
            <GamePanel
              front={ <div className="img-div" style={{ backgroundImage: `url(${this.state.loadNextImage ? this.state.nextQuestion.beforeImage.src : this.state.question.beforeImage.src})` }}></div> }
              back={ <div className="img-div" style={{ backgroundImage: `url(${this.state.backImage})` }}></div> }
              isAnswered={ this.state.isAnswered }
              isFlipped={ this.state.isAnswered }
              question={ this.state.question }
              checkAnswer={ event => this.checkAnswer(event) }
              isMobile={ this.props.windowSize.width < mobileBreakpoint }
              hideLogo={ () => this.props.hideLogo() }
              showLogo={ () => this.props.showLogo() }
              setInGame = { value => this.props.setInGame(value) }
              bottleImgNo={ this.props.bottleImgNo }
              waterDonatedAmount={ this.props.waterDonatedAmount }
              windowSize={ this.props.windowSize }
              correctAnswers={ this.props.correctAnswers }
              numberOfQuestions={ this.props.numberOfQuestions }
              moduleName="Sports"
              addWaterDonated={ this.props.addWaterDonated }
              clearScore={ () => this.clearScore() }
              score={ this.state.score }
              userId={ this.props.userId }
            />
          </div>
          :
          <div>
            {
              this.props.windowSize.width < mobileBreakpoint ? <MobileHomeIcon history={ this.props.history } /> : null
            }
            <Tagline windowSize={ this.props.windowSize } />
            <div
              className="sports-menu-wrap"
              ref={ (sportsMenuWrapElement) => { this.sportsMenuWrapElement = sportsMenuWrapElement } }
              style={{ transform: `scale(1, ${Math.min(1.47 - this.state.sportsMenuWrapHeight / this.props.windowSize.height, 1)}) translate(-50%, -35%)` }}
            >
              <NoScrollPanel title="Select Your Sports">
                <div className="sports-menu">
                  <div
                    id={ all }
                    className={ 
                      this.state.selectedSports.includes(football)
                      && this.state.selectedSports.includes(baseball)
                      && this.state.selectedSports.includes(basketball)
                      && this.state.selectedSports.includes(hockey)
                      && this.state.selectedSports.includes(soccer)
                      ? "sports-menu-item selected" 
                      : "sports-menu-item"
                    }
                    onClick={
                      () => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleAll();
                      }
                    }
                  >
                    <p>ALL</p>
                  </div>
                  <div
                    id={ football }
                    className={ this.state.selectedSports.includes(football) ? "sports-menu-item selected" : "sports-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ football }>FOOTBALL</p>
                  </div>
                  <div
                    id={ basketball }
                    className={ this.state.selectedSports.includes(basketball) ? "sports-menu-item selected" : "sports-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ basketball }>BASKETBALL</p>
                  </div>
                  <div
                    id={ baseball }
                    className={ this.state.selectedSports.includes(baseball) ? "sports-menu-item selected" : "sports-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ baseball }>BASEBALL</p>
                  </div>
                  <div
                    id={ hockey }
                    className={ this.state.selectedSports.includes(hockey) ? "sports-menu-item selected" : "sports-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ hockey }>HOCKEY</p>
                  </div>
                  <div
                    id={ soccer }
                    className={ this.state.selectedSports.includes(soccer) ? "sports-menu-item selected" : "sports-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ soccer }>SOCCER</p>
                  </div>
                </div>
              </NoScrollPanel>
              <NoScrollPanel title="select years" style={{ paddingBottom: "1.5vh" }}>
                <Slider
                  min={ 1978 }
                  max={ 2020 }
                  minRange={ this.state.selectedSports.includes(soccer) && this.state.selectedSports.length === 1 ? 4 : 2 }
                  value={ this.state.selectedYears }
                  onChange={ value => this.updateSelectedYears(value) }
                  disabled={ this.state.selectedSports.length === 0 }
                />
              </NoScrollPanel>
              <PlayButton onClick={ () => this.startGame() } disabled={ this.state.selectedSports.length === 0 } buttonSound={ this.props.buttonSound } isSoundOn={ this.props.isSoundOn } />
            </div>
            
          </div>
        }
      </div>
    );
  }
}