import React from 'react';
import './leaderboard.css';
import { withRouter } from 'react-router-dom';
import MoviesIcon from '../../assets/menu-icons/Movies.png';
import MtgIcon from '../../assets/menu-icons/Mtg.png';
import SportsIcon from '../../assets/menu-icons/Sports.png';
import TvIcon from '../../assets/menu-icons/Tv.png';
import AlbumsIcon from '../../assets/menu-icons/Albums.png';
import ArtIcon from '../../assets/menu-icons/Art.png';
import CarsIcon from '../../assets/menu-icons/Cars.png';
import DogsIcon from '../../assets/menu-icons/Dogs.png';
import ReactGA from 'react-ga';
import MobileExitIcon from '../../components/mobile-exit-icon/mobile-exit-icon';

const _overall = "_overall";
const _albums = "_albums";
const _art = "_art";
const _cars = "_cars";
const _dogs = "_dogs";
const _mtg = "_mtg";
const _movies = "_movies";
const _sports = "_sports";
const _tv = "_tv";

const _allTime = { title: "All Time", dataName: "topAllTime" };
const _30Days = { title: "Past 30 Days", dataName: "top30Days" };
const _5Days = { title: "Past 5 Days", dataName: "top5Days" };

const categories = [
  {
    name: _overall,
    title: "overall",
    iconSrc: "/android-chrome-192x192.png"
  },
  {
    name: _albums,
    title: "albums",
    iconSrc: AlbumsIcon
  },
  {
    name: _art,
    title: "art",
    iconSrc: ArtIcon
  },
  {
    name: _cars,
    title: "cars",
    iconSrc: CarsIcon
  },
  {
    name: _dogs,
    title: "dogs",
    iconSrc: DogsIcon
  },
  {
    name: _mtg,
    title: "mtg",
    iconSrc: MtgIcon
  },
  {
    name: _movies,
    title: "movies",
    iconSrc: MoviesIcon
  },
  {
    name: _sports,
    title: "sports",
    iconSrc: SportsIcon
  },
  {
    name: _tv,
    title: "tv",
    iconSrc: TvIcon
  }
];

export class Leaderboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCategory: _overall,
      currentTimeRange: _allTime,
      isLoading: true,
      data: null
    };
  }

  componentDidMount() {
    ReactGA.set({ page: "/leaderboard" });
    ReactGA.pageview("/leaderboard");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    this.props.setIsLoading(false);

    this.fetchData(_overall);
  }

  fetchData(category) {
    this.setState({ isLoading: true });
    fetch(`/api/get-leaderboard-data?category=${categories.find(c => c.name === category).title}`)
    .then(response => response.json())
    .then(result => {
      if (result.success) {
        this.setState({ isLoading: false, data: result.data });
      } else {
        this.setState({ isLoading: false });
      }
    })
    .catch(() => this.setState({ isLoading: false }));
  }

  hangleCategoryChange(category) {
    this.setState({
      currentCategory: category, data: null
    }, () => this.fetchData(category));
  }

  render() {
    return (
      <div>
        <div className={ `leaderboard-container ${this.props.isMobile ? "leaderboard-container-mobile" : ""}` } style={{ minHeight: `${this.props.minHeight*0.80}px` }}>
          <MobileExitIcon style={{ paddingRight: "0.5em", paddingTop: "0.5em" }} history={ this.props.history } />
          <div className="leaderboard-content">
            <h1><b>Donation</b>&nbsp;Leaderboard</h1>
            <div className="leaderboard-categories-wrap">
              <div className={`leaderboard-category-wrap ${this.state.currentCategory === _overall ? "selected" : ""}`} onClick={ () => this.hangleCategoryChange(_overall) }>
                <div className="leaderboard-category">
                  <img src="/android-chrome-192x192.png" alt="overall" />
                </div>
                <p>{ categories.find(category => category.name === _overall).title }</p>
              </div>
              <div className={`leaderboard-category-wrap ${this.state.currentCategory === _albums ? "selected" : ""}`} onClick={ () => this.hangleCategoryChange(_albums) }>
                <div className="leaderboard-category">
                  <img src={ AlbumsIcon } alt="overall" />
                </div>
                <p>{ categories.find(category => category.name === _albums).title }</p>
              </div>
              <div className={`leaderboard-category-wrap ${this.state.currentCategory === _art ? "selected" : ""}`} onClick={ () => this.hangleCategoryChange(_art) }>
                <div className="leaderboard-category">
                  <img src={ ArtIcon } alt="overall" />
                </div>
                <p>{ categories.find(category => category.name === _art).title }</p>
              </div>
              <div className={`leaderboard-category-wrap ${this.state.currentCategory === _cars ? "selected" : ""}`} onClick={ () => this.hangleCategoryChange(_cars) }>
                <div className="leaderboard-category">
                  <img src={ CarsIcon } alt="overall" />
                </div>
                <p>{ categories.find(category => category.name === _cars).title }</p>
              </div>
              <div className={`leaderboard-category-wrap ${this.state.currentCategory === _dogs ? "selected" : ""}`} onClick={ () => this.hangleCategoryChange(_dogs) }>
                <div className="leaderboard-category">
                  <img src={ DogsIcon } alt="overall" />
                </div>
                <p>{ categories.find(category => category.name === _dogs).title }</p>
              </div>
              <div className={`leaderboard-category-wrap ${this.state.currentCategory === _mtg ? "selected" : ""}`} onClick={ () => this.hangleCategoryChange(_mtg) }>
                <div className="leaderboard-category">
                  <img src={ MtgIcon } alt="overall" />
                </div>
                <p>{ categories.find(category => category.name === _mtg).title }</p>
              </div>
              <div className={`leaderboard-category-wrap ${this.state.currentCategory === _movies ? "selected" : ""}`} onClick={ () => this.hangleCategoryChange(_movies) }>
                <div className="leaderboard-category">
                  <img src={ MoviesIcon } alt="overall" />
                </div>
                <p>{ categories.find(category => category.name === _movies).title }</p>
              </div>
              <div className={`leaderboard-category-wrap ${this.state.currentCategory === _sports ? "selected" : ""}`} onClick={ () => this.hangleCategoryChange(_sports) }>
                <div className="leaderboard-category">
                  <img src={ SportsIcon } alt="overall" />
                </div>
                <p>{ categories.find(category => category.name === _sports).title }</p>
              </div>
              <div className={`leaderboard-category-wrap ${this.state.currentCategory === _tv ? "selected" : ""}`} onClick={ () => this.hangleCategoryChange(_tv) }>
                <div className="leaderboard-category">
                  <img src={ TvIcon } alt="overall" />
                </div>
                <p>{ categories.find(category => category.name === _tv).title }</p>
              </div>
            </div>

            <h2>
              <img src={ categories.find(category => category.name === this.state.currentCategory).iconSrc } alt="icon" height="35px" />
              <b>TOP { categories.find(category => category.name === this.state.currentCategory).title.toUpperCase() }</b>
            </h2>
            
            {
              !this.props.isMobile ?
                <div className="leaderboard-header">
                  <p>All Time:&nbsp;&nbsp;<b><span className="orange">{ this.state.data?.topAllTime.find(item => item.UserID === this.props.user?.id)?.UserRank }</span></b></p>
                  <p>Past 30 Days:&nbsp;&nbsp;<b><span className="orange">{ this.state.data?.top30Days.find(item => item.UserID === this.props.user?.id)?.UserRank }</span></b></p>
                  <p>Past 5 Days:&nbsp;&nbsp;<b><span className="orange">{ this.state.data?.top5Days.find(item => item.UserID === this.props.user?.id)?.UserRank }</span></b></p>
                </div>
              : 
                <>
                  <div className="mobile-ranking">
                    <p>
                      { this.state.currentTimeRange.title.toUpperCase() } RANK:&nbsp;&nbsp;
                      {
                        this.state.data && this.state.data[this.state.currentTimeRange.dataName] && this.state.data[this.state.currentTimeRange.dataName].length > 0 ?
                          this.state.data[this.state.currentTimeRange.dataName].find(item => item.UserID && item.UserID === this.props.user?.id)?.UserRank
                        : ""
                        
                      }
                    </p>
                  </div>
                  <div className="time-range-menu">
                    <div
                      className={`time-range-menu-item ${this.state.currentTimeRange.title === _allTime.title ? "selected" : ""}`}
                      onClick={ () => this.setState({ currentTimeRange: _allTime })
                    }>
                      <p>{ _allTime.title }</p>
                    </div>
                    <div
                      className={`time-range-menu-item ${this.state.currentTimeRange.title === _30Days.title ? "selected" : ""}`}
                      onClick={ () => this.setState({ currentTimeRange: _30Days })
                    }>
                      <p>{ _30Days.title }</p>
                    </div>
                    <div
                      className={`time-range-menu-item ${this.state.currentTimeRange.title === _5Days.title ? "selected" : ""}`}
                      onClick={ () => this.setState({ currentTimeRange: _5Days })
                    }>
                      <p>{ _5Days.title }</p>
                    </div>
                  </div>
                  <hr />
                </>
            }

            <div className="leaderboard-table">
              {
                this.state.isLoading ?
                  <div className="loader"></div>
                :
                !this.props.isMobile ? 
                  <>
                    <div className="leaderboard-column">
                      {
                        !this.state.data?.topAllTime?.length > 0 ?
                          <p>No data</p>
                        :
                          this.state.data?.topAllTime.map(item => {
                            return (
                              <div key={ item.UserRank } className={`leaderboard-column-row ${ item.UserID && item.UserID === this.props.user?.id ? "highlited" : "" }`}>
                                <p>{ item.UserRank }.</p>
                                <p>{ item.Username ? item.Username : item.Name }</p>
                                <p className="leaderboard-amount">{ (item.Total * 5).toLocaleString() }</p>
                                <p>cups</p>
                              </div>
                            );
                          })
                      }
                    </div>
                    <div className="leaderboard-column">
                      {
                        !this.state.data?.top30Days?.length > 0 ?
                          <p>No data</p>
                        :
                          this.state.data?.top30Days.map(item => {
                            return (
                              <div key={ item.UserRank } className={`leaderboard-column-row ${ item.UserID && item.UserID === this.props.user?.id ? "highlited" : "" }`}>
                                <p>{ item.UserRank }.</p>
                                <p>{ item.Username ? item.Username : item.Name }</p>
                                <p className="leaderboard-amount">{ (item.Total * 5).toLocaleString() }</p>
                                <p>cups</p>
                              </div>
                            );
                          })
                      }
                    </div>
                    <div className="leaderboard-column">
                      {
                        !this.state.data?.top5Days?.length > 0 ?
                          <p>No data</p>
                        :
                          this.state.data?.top5Days.map(item => {
                            return (
                              <div key={ item.UserRank } className={`leaderboard-column-row ${ item.UserID && item.UserID === this.props.user?.id ? "highlited" : "" }`}>
                                <p>{ item.UserRank }.</p>
                                <p>{ item.Username ? item.Username : item.Name }</p>
                                <p className="leaderboard-amount">{ (item.Total * 5).toLocaleString() }</p>
                                <p>cups</p>
                              </div>
                            );
                          })
                      }
                    </div>
                  </>
                :
                  <div className="leaderboard-column">
                    {
                      !this.state.data || !this.state.data[this.state.currentTimeRange.dataName] || !(this.state.data[this.state.currentTimeRange.dataName].length > 0) ?
                        <p>No data</p>
                      :
                        this.state.data[this.state.currentTimeRange.dataName].map(item => {
                          return (
                            <div key={ item.UserRank } className={`leaderboard-column-row ${ item.UserID && item.UserID === this.props.user?.id ? "highlited" : "" }`}>
                              <p><b>{ item.UserRank }.</b></p>
                              <p>{ item.Username ? item.Username : item.Name }</p>
                              <p className="leaderboard-amount">{ (item.Total * 5).toLocaleString() }</p>
                              <p>cups</p>
                            </div>
                          );
                        })
                    }
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(Leaderboard);
