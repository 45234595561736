import React from 'react';
import './movies.css';
import Tagline from '../../components/tagline/tagline';
import NoScrollPanel from '../../components/no-scroll-panel/no-scroll-panel';
import GamePanel from '../../components/game-panel/game-panel';
import { shuffle, parseCvsAsync, postData } from '../../components/helpers/helpers';
import { nextQuestionTimeout, mobileBreakpoint } from '../../const';
import MobileHomeIcon from '../../components/mobile-home-icon/mobile-home-icon';
import PlayButton from '../../components/play-button/play-button';
import moviesQuestions from '../../assets/movies-questions.csv';
import Slider from '../../components/slider/slider';
import ReactGA from 'react-ga';
import CorrectAnswerSound from '../../assets/correct-answer-sound.mp3';
import WrongAnswerSound from '../../assets/wrong-answer-sound.mp3';

export default class Movies extends React.Component {
  correctAnswerSound;
  wrongAnswerSound;

  constructor(props) {
    super(props);

    this.state = {
      selectedYears: [1959, 2020],
      startGame: false,
      isAnswered: false,
      questions: null,
      loadNextImage: false,
      backImage: null,
      usedQuestions: [],
      question: {
        value: "",
        beforeImage: "",
        afterImage: "",
        answers: [],
        correct: false,
        selected: ""
      },
      nextQuestion: {
        value: "",
        beforeImage: "",
        afterImage: "",
        answers: [],
        correct: false,
        selected: ""
      },
      score: 0
    }
  }

  componentDidMount() {
    ReactGA.set({ page: "/movies" });
    ReactGA.pageview("/movies");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    parseCvsAsync(moviesQuestions).then(questions => {
      this.setState({
        questions: questions
      });
    });

    this.correctAnswerSound = new Audio(CorrectAnswerSound);
    this.wrongAnswerSound = new Audio(WrongAnswerSound);

    this.props.setIsLoading(false);
  }

  updateSelectedYears(value) {
    this.setState({ selectedYears: value });
  }

  startGame() {
    const filtered = this.state.questions?.filter(q => 
                                                    (this.state.selectedYears[0] === 1959 && q.Year < this.state.selectedYears[1])
                                                    || (q.Year >= this.state.selectedYears[0] && q.Year <= this.state.selectedYears[1])
                                                  );
    this.setState({
      startGame: true,
      questions: [...filtered]
    }, () => this.getNextQuestion(() => this.loadNextQuestion()));
  }

  getNextQuestion(loadNextQuestion) {
    const noOfQs = this.state.questions.length;
    let chosenQ;
    do {
      chosenQ = Math.floor(Math.random() * noOfQs);
    } while(this.state.usedQuestions.includes(chosenQ));

    const filteredForAnswers = this.state.questions
                                .filter(q => 
                                  (this.state.selectedYears[0] === 1959 && q.Year < this.state.selectedYears[1])
                                  || (q.Year >= this.state.selectedYears[0] && q.Year <= this.state.selectedYears[1])
                                );
    let ans1;
    let ans2;
    let ans3;
    do {
      ans1 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans1].MovieName === this.state.questions[chosenQ].MovieName);
    do {
      ans2 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans2].MovieName === this.state.questions[chosenQ].MovieName
            || filteredForAnswers[ans2].MovieName === filteredForAnswers[ans1].MovieName);
    do {
      ans3 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans3].MovieName === this.state.questions[chosenQ].MovieName
      || filteredForAnswers[ans3].MovieName === filteredForAnswers[ans2].MovieName
      || filteredForAnswers[ans3].MovieName === filteredForAnswers[ans1].MovieName);

    const answers = [
      this.state.questions[chosenQ].MovieName,
      filteredForAnswers[ans1].MovieName,
      filteredForAnswers[ans2].MovieName,
      filteredForAnswers[ans3].MovieName
    ];

    const beforeImage = new Image();
    beforeImage.src = `/imgs/movies/before/${this.state.questions[chosenQ].BeforeImage}`;

    const afterImage = new Image();
    afterImage.src = `/imgs/movies/after/${this.state.questions[chosenQ].AfterImage}`;
    
    this.setState({
      nextQuestion: {
        value: this.state.questions[chosenQ].MovieName,
        beforeImage: beforeImage,
        afterImage: afterImage,
        beforeImageName: this.state.questions[chosenQ].BeforeImage,
        afterImageName: this.state.questions[chosenQ].AfterImage,
        answers: shuffle(answers),
        correct: false,
        selected: ""
      },
      usedQuestions: this.state.usedQuestions.length < this.state.questions.length - 1 ? [
        ...this.state.usedQuestions,
        chosenQ
      ] : [chosenQ]
    }, () => {
      if(loadNextQuestion) loadNextQuestion();
    });
  }

  loadNextQuestion() {
    this.setState({
      question: {
        ...this.state.nextQuestion
      }
    });
  }

  checkAnswer(event) {
    const isAnsweredCorrectlly = this.state.question.value === event.target.id;
    this.getNextQuestion();
    
    if(isAnsweredCorrectlly) {
      if(this.props.isSoundOn) this.correctAnswerSound.play();
      this.props.addWaterDonated(5);
      this.setState({ score: this.state.score + 1 });
      ReactGA.event({
        category: 'Movies',
        action: 'Answered correctly'
      });
    } else {
      if(this.props.isSoundOn) this.wrongAnswerSound.play();
      this.props.addQuestionAnswered();
      ReactGA.event({
        category: 'Movies',
        action: 'Answered incorrectly'
      });
    }

    postData('/api/addanswer', {
      UserID: this.props.userId,
      Category: "movies",
      BeforeImage: this.state.question.beforeImageName,
      AfterImage: this.state.question.afterImageName,
      IsCorrect: isAnsweredCorrectlly ? 1 : 0
    });

    this.setState({
      backImage: this.state.question.afterImage.src,
      question: {
        ...this.state.question,
        selected: event.target.id,
        correct: this.state.question.value
      }
    }, () => {
      setTimeout(() => {
        this.setState({
          isAnswered: true
        });
      }, 50);
    });

    setTimeout(() => {
      this.setState({ loadNextImage: true });
    }, 250);
    
    setTimeout(() => {
      this.loadNextQuestion();
      this.setState({ isAnswered: false, loadNextImage: false });
    }, nextQuestionTimeout * 1000);
  }

  clearScore() {
    this.setState({ score: 0 });
  }

  render() {
    return(
      <div className="movies">
        {
          this.props.windowSize.width < mobileBreakpoint ? <MobileHomeIcon history={ this.props.history } /> : null
        }
        { 
          this.state.startGame
          ? 
          <div>
            <GamePanel
              front={ <div className="img-div" style={{ backgroundImage: `url(${this.state.loadNextImage ? this.state.nextQuestion.beforeImage.src : this.state.question.beforeImage.src})` }}></div> }
              back={ <div className="img-div" style={{ backgroundImage: `url(${this.state.backImage})` }}></div> }
              isAnswered={ this.state.isAnswered }
              isFlipped={ this.state.isAnswered }
              question={ this.state.question }
              checkAnswer={ event => this.checkAnswer(event) }
              isMobile={ this.props.windowSize.width < mobileBreakpoint }
              hideLogo={ () => this.props.hideLogo() }
              showLogo={ () => this.props.showLogo() }
              setInGame = { value => this.props.setInGame(value) }
              bottleImgNo={ this.props.bottleImgNo }
              waterDonatedAmount={ this.props.waterDonatedAmount }
              windowSize={ this.props.windowSize }
              correctAnswers={ this.props.correctAnswers }
              numberOfQuestions={ this.props.numberOfQuestions }
              moduleName="Movies"
              addWaterDonated={ this.props.addWaterDonated }
              clearScore={ () => this.clearScore() }
              score={ this.state.score }
              userId={ this.props.userId }
            />
          </div>
          :
          <div>
            {
              this.props.windowSize.width < mobileBreakpoint ? <MobileHomeIcon history={ this.props.history } /> : null
            }
            <Tagline windowSize={ this.props.windowSize } />
            <div className="movies-menu-wrap">
              <NoScrollPanel title="movies" style={{ paddingBottom: "1.5vh" }}>
                <Slider min={ 1960 } max={ 2020 } minRange={ 10 } allowPreYear={ true } value={ this.state.selectedYears } onChange={ value => this.updateSelectedYears(value) } />
              </NoScrollPanel>
              <PlayButton onClick={ () => this.startGame() } buttonSound={ this.props.buttonSound } isSoundOn={ this.props.isSoundOn } />
            </div>
          </div>
        }
      </div>
    );
  }
}