import React from 'react';
import './game-panel.css';
import ReactCardFlip from 'react-card-flip';
import bottle1 from '../../assets/water-bottle/Bottle1.png';
import bottle2 from '../../assets/water-bottle/Bottle2.png';
import bottle3 from '../../assets/water-bottle/Bottle3.png';
import bottle4 from '../../assets/water-bottle/Bottle4.png';
import bottle5 from '../../assets/water-bottle/Bottle5.png';
import bottle6 from '../../assets/water-bottle/Bottle6.png';
import bottle7 from '../../assets/water-bottle/Bottle7.png';
import { numberOfQuestionsPerGame, nextQuestionTimeout } from '../../const';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import logo from '../../assets/game-well-circle.png';
import ReactGA from 'react-ga';
import { postData } from '../../components/helpers/helpers';
import CountUp from 'react-countup';
import { withRouter } from 'react-router-dom';
import AmazonAd from '../../assets/amazon-ad.png';
import StarMark from '../../assets/star-mark.png';

export class GamePanel extends React.Component {
  correctAnswerSound;
  wrongAnswerSound;
  amazonAdImg

  constructor(props) {
    super(props);

    this.state = {
      showConfetti: false,
      isConfettiShowed: false,
      questionNumber: 0,
      percentile: 0,
      percentileCalculated: false,
      /*showAd: false,
      showAdNextTime: true*/
    }
  }

  componentDidMount() {
    if(this.props.isMobile) {
      this.props.hideLogo();
      this.props.setInGame(true);
    }
    this.amazonAdImg = new Image().src = AmazonAd;
  }

  componentWillUnmount() {
    this.props.showLogo();
    this.props.setInGame(false);
  }

  componentDidUpdate() {
    if (this.props.bottleImgNo !== 6 && this.state.isConfettiShowed) {
      this.setState({ isConfettiShowed: false });
    }

    if (this.props.bottleImgNo === 6 && !this.state.isConfettiShowed) {
      this.setState({ showConfetti: true, isConfettiShowed: true });
      setTimeout(() => {
        this.setState({
          showConfetti: false
        });
      }, 1500);
    }

    if(this.state.questionNumber === numberOfQuestionsPerGame && !this.state.percentileCalculated) {
      this.setState({ percentileCalculated: true }, () => {
        fetch(`/api/getPercentile?Score=${this.props.score}&Category=${this.props.moduleName}`)
        .then(response => response.json())
        .then(data => {
          this.setState({ percentile: data.Percentile, percentileCalculated: true });
          postData('/api/addGameSet', {
            Category: this.props.moduleName,
            Score: this.props.score,
            UserId: this.props.userId
          });
          ReactGA.event({
            category: this.props.moduleName,
            action: 'Game Set Finished',
            value: this.props.score
          });
        });
      });
    }
  }

  playAgain(/*showAd, showAdNextTime*/) {
    this.setState({ questionNumber: 0, percentile: 0, percentileCalculated: false, /*showAd: showAd, showAdNextTime: showAdNextTime*/ });
  }

  render() {
    return (
      <div
        className={ `game-panel ${this.props.isMobile ? "mobile" : ""}` }
        style={{ height: this.props.isMobile ? this.props.windowSize.height * 0.96 : "84vh" }}
      >
        {
          !this.props.isMobile ?
            <div className="in-game-tagline">
              {
                this.props.windowSize.height >= 700 ?
                  <div className="in-game-tagline-bg">
                    <h1>PLAY WITH PURPOSE</h1>
                    <p>DONATE WATER WITH EVERY CORRECT ANSWER!</p>
                  </div>
                :
                  null
              }
            </div>
          : <div className="in-game-counter-bottle-mobile-wrap">
              <div className="in-game-counter-bottle-mobile">
                <div className="in-game-bottle-mobile">
                  <img
                    src={
                      this.props.bottleImgNo === 0 ? bottle1
                      : this.props.bottleImgNo === 1 ? bottle2
                      : this.props.bottleImgNo === 2 ? bottle3
                      : this.props.bottleImgNo === 3 ? bottle4
                      : this.props.bottleImgNo === 4 ? bottle5
                      : this.props.bottleImgNo === 5 ? bottle6
                      : this.props.bottleImgNo === 6 ? bottle7
                      : null
                    }
                    alt="Bottle" 
                  />
                  { this.props.bottleImgNo === 6 && this.state.showConfetti ?
                      <img
                        className="confetti"
                        src={ require("../../assets/water-bottle/ConfettiAnimation.gif") + "?a=" + Math.random() }
                        alt="confetti animation"
                      />
                    :
                      null
                  }
                </div>
                <div className="in-game-counter-wrap-mobile">
                  <p className="percentage">{ this.props.correctAnswers }/{ this.props.numberOfQuestions } Correct - { this.props.numberOfQuestions !== 0 ? Math.round(this.props.correctAnswers/this.props.numberOfQuestions * 100) : 0 }%</p>
                  <div className="in-game-counter-mobile">
                    <p>{ this.props.waterDonatedAmount }</p><p style={{ fontSize: "3vh", marginLeft: "2vw" }}>Cups</p>
                  </div>
                  <p className="in-game-donated-mobile">DONATED!</p>
                </div>
              </div>
            </div>
        }
        <div className={ `question-container ${this.props.isMobile ? "mobile" : ""}` }>
          <div className={ `img-container ${this.props.isMobile ? "mobile" : ""}` }>
            <SwitchTransition>
              <CSSTransition
                key={this.state.questionNumber < numberOfQuestionsPerGame}
                timeout={350}
                classNames="page"
                unmountOnExit
              >
                {
                  this.state.questionNumber < numberOfQuestionsPerGame ?
                  <ReactCardFlip isFlipped={ this.props.isFlipped } >
                    { this.props.front }
                    { this.props.back }
                  </ReactCardFlip>
                  :
                  <div className="score ad">
                    <div>
                      <img src={ logo } alt="The Game Well Logo" />
                      <h1>You scored<br/><span className="orange-text">{ this.props.score } out of { numberOfQuestionsPerGame }.</span></h1>
                      <h2>You scored <span className="orange-text">better than<br/><CountUp delay={1} duration={1} useEasing={false} end={ this.state.percentile * 100 } />%</span> of all players on this set!</h2>
                      {
                        /*this.state.showAdNextTime*/ true ?
                          <h2>DONATE an EXTRA 100 Cups if you SHARE your score below!</h2>
                        :
                          <>
                            <h2 className="ad-h2-text">DONATE an EXTRA 500 Cups<br />if you make your Amazon bookmark<br />The Game Well's affiliate link!</h2>
                            <p className="ad-p-text">You shop. Amazon gives.<br />(it's totally free!)</p>
                          </>
                      }
                    </div>
                  </div>
                }
              </CSSTransition>
            </SwitchTransition>
          </div>
          <div className={ `aswers-container ${this.props.isMobile ? "mobile" : ""}` }>
            {/*
              this.state.showAdNextTime && this.state.showAd ?
              <div className="cookies-popup-bg ad">
                <div className="mobile-add-popup">
                  <MobileExitIcon style={{ paddingTop: "1em", paddingRight: "1em" }} onClick={ () => this.setState({ showAd: false, showAdNextTime: false }) } />
                  <div>
                    <p>There's something<br />NEW to shop for!</p>
                    <img className="amzn-ad-mobile" src={this.amazonAdImg} alt="Amazon ad" />
                    <div className="shop-now-button" onClick={() => {
                      ReactGA.event({
                        category: this.props.moduleName,
                        action: 'Mobile Ad Shop Now Link Click'
                      });
                      window.open("https://www.amazon.com/?tag=thegamewell-20", "_blank");
                    }}>
                      <p>SHOP NOW</p>
                    </div>
                    <p className="mobile-ad-or">or</p>
                    <p className="mobile-ad-donate-text">Donate <span className="orange">for free</span> with your<br />everyday purchases!</p>
                    <div className="shop-now-button" onClick={() => {
                      ReactGA.event({
                        category: this.props.moduleName,
                        action: 'Mobile Ad Email Link Click'
                      });
                      window.open("mailto:?subject=An%20Amazon%20link%20to%20help%20The%20Game%20Well&body=Dear%20Me%2C%0A%0AI%20can%E2%80%99t%20thank%20myself%20enough%20for%20sending%20myself%20a%20link%20and%20a%20reminder%20to%20change%20my%20Amazon%20bookmark%20to%20the%20link%20below%3A%0A%0Ahttps%3A%2F%2Fwww.amazon.com%2F%3Ftag%3Dthegamewell-20%0A%0AChanging%20my%20Amazon%20bookmark%20to%20this%20link%20will%20ensure%20that%20TheGameWell%20can%20continue%20to%20help%20out%20those%20in%20need%20of%20access%20to%20clean%2C%20reliable%20water%20around%20the%20world.%0A%0AYours%20Truly%2C%0AMe", "_blank");
                    }}>
                      <img src={StarMark} alt="star" />
                      <p>EMAIL me the link</p>
                    </div>
                    <img className="amazon-a-logo" src={AmazonALogo} alt="Amazon A Logo" />
                    <p className="mobile-ad-line">You shop.<br />Amazon gives.</p>
                  </div>
                </div>
              </div>
              : null*/
            }
            {
              this.state.questionNumber < numberOfQuestionsPerGame ?
                <>{
                  this.props.question?.answers.map(answer => {
                    return(
                      <div
                        className={ `answer-item
                                    ${this.props.question.correct === answer ? "correct" : ""}
                                    ${this.props.question.selected === answer ? "selected" : ""}
                                    ${this.props.question.selected === answer && this.props.question.selected !== this.props.question.correct ? "incorrect-selected" : ""}
                                    ${this.props.question.selected && this.props.question.correct !== answer ? "incorrect" : ""}
                                  `}
                        key={ answer }
                        id={ answer }
                        onClick={
                          event => {
                            if(!this.props.isAnswered) {
                              this.props.checkAnswer(event);
                              setTimeout(() => {
                                this.setState({ questionNumber: this.state.questionNumber + 1 });
                              }, (nextQuestionTimeout * 1000) - 350)
                            }
                          }
                        }
                      >
                        <p key={ answer } id={ answer }>{ answer }</p>
                      </div>
                    );
                  })
                }</>
              :
              <>
                {
                  /*!this.state.showAdNextTime*/ false ?
                    <>
                      <div
                        className="answer-item"
                        key={ "Facebook" }
                        id={ "Facebook" }
                        onClick={() => {
                          postData('/api/addShareEvent', {
                            UserID: this.props.userId,
                            SocialMediaName: "Email"
                          });
                          ReactGA.event({
                            category: this.props.moduleName,
                            action: 'Mobile Ad Email Link Click'
                          });
                          this.props.addWaterDonated(500, true);
                          this.playAgain(false, true);
                          this.props.clearScore();
                          window.open("mailto:?subject=An%20Amazon%20link%20to%20help%20The%20Game%20Well&body=Dear%20Me%2C%0A%0AI%20can%E2%80%99t%20thank%20myself%20enough%20for%20sending%20myself%20a%20link%20and%20a%20reminder%20to%20change%20my%20Amazon%20bookmark%20to%20the%20link%20below%3A%0A%0Ahttps%3A%2F%2Fwww.amazon.com%2F%3Ftag%3Dthegamewell-20%0A%0AChanging%20my%20Amazon%20bookmark%20to%20this%20link%20will%20ensure%20that%20TheGameWell%20can%20continue%20to%20help%20out%20those%20in%20need%20of%20access%20to%20clean%2C%20reliable%20water%20around%20the%20world.%0A%0AYours%20Truly%2C%0AMe", "_blank");
                        }}
                      >
                        <img src={StarMark} alt="star" />
                        <p>EMAIL me the link</p>
                      </div>
                      <div className="answer-item" key={ "placeholder" } id={ "placeholder" } style={{ visibility: "hidden" }}></div>
                      <div
                        className="answer-item keep-playing"
                        key={ "play" }
                        id={ "play" }
                        onClick={ () => { this.playAgain(false, true); this.props.clearScore(); } }
                      >
                        <p key={ "play" } id={ "play" }>KEEP PLAYING</p>
                        
                      </div>
                    </>
                  :
                    <>
                      <div
                        className="answer-item"
                        key={ "Facebook" }
                        id={ "Facebook" }
                        onClick={ 
                          () => {
                            postData('/api/addShareEvent', {
                              UserID: this.props.userId,
                              SocialMediaName: "Facebook"
                            });
                            ReactGA.event({
                              category: this.props.moduleName,
                              action: 'Shared on Facebook'
                            });
                            this.props.addWaterDonated(100, true);
                            window.open("https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Fwww.thegamewell.com%2F&display=popup&ref=plugin&src=share_button", "_blank")
                          }
                        }
                      >
                        <p key={ "Facebook" } id={ "Facebook" }>SHARE on Facebook</p>
                      </div>
                      <div
                        className="answer-item"
                        key={ "Twitter" }
                        id={ "Twitter" }
                        onClick={
                          () => {
                            postData('/api/addShareEvent', {
                              UserID: this.props.userId,
                              SocialMediaName: "Twitter"
                            });
                            ReactGA.event({
                              category: this.props.moduleName,
                              action: 'Shared on Twitter'
                            });
                            this.props.addWaterDonated(100, true);
                            window.open(`https://twitter.com/intent/tweet?text=I just scored ${this.props.score}/${numberOfQuestionsPerGame} on TheGameWell, and donated over ${this.props.waterDonatedAmount + 100 } cups of water to those in need around the world. You too can test your knowledge of Sports, Art, Dogs, Cars, Movies, TV, MTG and more at https://www.thegamewell.com&hashtags=thegamewell,playwithpurpose&via=thegamewell`, "_blank")
                          }
                        }
                      >
                        <p key={ "Twitter" } id={ "Twitter" }>SHARE on Twitter</p>
                      </div>
                      <div className="answer-item" key={ "placeholder" } id={ "placeholder" } style={{ visibility: "hidden" }}></div>
                      <div
                        className="answer-item keep-playing"
                        key={ "play" }
                        id={ "play" }
                        onClick={ () => { this.playAgain(true, true); this.props.clearScore(); } }
                      >
                        <p key={ "play" } id={ "play" }>KEEP PLAYING</p>
                        
                      </div>
                    </>
                }
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(GamePanel);