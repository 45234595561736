import React from 'react';
import exitIcon from '../../assets/home-icon.png';

export const MobileHomeIcon = props => {
  return(
    <div
      className="mobile-exit-icon"
      onClick={ () => props.history.push('/') }
      style={ props.style }
    >
      <img src={ exitIcon } alt="Home icon" />
    </div>
  );
}

export default MobileHomeIcon;