import React from 'react';
import './cars.css';
import Tagline from '../../components/tagline/tagline';
import NoScrollPanel from '../../components/no-scroll-panel/no-scroll-panel';
import GamePanel from '../../components/game-panel/game-panel';
import { shuffle, parseCvsAsync, postData, toLowerCaseExceptFirst } from '../../components/helpers/helpers';
import { nextQuestionTimeout, mobileBreakpoint } from '../../const';
import MobileHomeIcon from '../../components/mobile-home-icon/mobile-home-icon';
import carsQuestions from '../../assets/cars-questions.csv';
import PlayButton from '../../components/play-button/play-button';
import ReactGA from 'react-ga';
import CorrectAnswerSound from '../../assets/correct-answer-sound.mp3';
import WrongAnswerSound from '../../assets/wrong-answer-sound.mp3';

const allModes = "_allModes";
const asia = "_asia";
const europe = "_europe";
const us_australia = "_us_australia";

export default class Cars extends React.Component {
  correctAnswerSound;
  wrongAnswerSound;

  constructor(props) {
    super(props);

    this.state = {
      selectedGameModes: [],
      startGame: false,
      isAnswered: false,
      questions: null,
      loadNextImage: false,
      backImage: null,
      usedQuestions: [],
      question: {
        value: "",
        beforeImage: "",
        beforeImageName: "",
        afterImage: "",
        afterImageName: "",
        answers: [],
        correct: false,
        selected: ""
      },
      nextQuestion: {
        value: "",
        img: "",
        afterImage: "",
        answers: [],
        correct: false,
        selected: ""
      },
      score: 0
    }
  }

  componentDidMount() {
    ReactGA.set({ page: "/cars" });
    ReactGA.pageview("/cars");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();

    parseCvsAsync(carsQuestions).then(questions => {
      this.setState({
        questions: questions
      });
    });

    // preloading
    this.correctAnswerSound = new Audio(CorrectAnswerSound);
    this.wrongAnswerSound = new Audio(WrongAnswerSound);

    this.props.setIsLoading(false);
  }

  toggleSelectedMode(event) {
    if(this.state.selectedGameModes.includes(event.target.id)) {
      let slecetedGameMods = [...this.state.selectedGameModes];
      slecetedGameMods.splice(slecetedGameMods.findIndex(el => el === event.target.id), 1);

      this.setState({
        selectedGameModes: [...slecetedGameMods]
      });
    } else {
      this.setState({
        selectedGameModes: [
          ...this.state.selectedGameModes,
          event.target.id
        ]
      });
    }
  }

  toggleAll() {
    if(this.state.selectedGameModes.length === 3) {
      this.setState({
        selectedGameModes: []
      });
    } else {
      this.setState({
        selectedGameModes: [asia, europe, us_australia]
      });
    }
  }

  startGame() {
    const filtered = this.state.questions?.filter(q => 
      (this.state.selectedGameModes.includes(asia) && q.Region === "a")
      || (this.state.selectedGameModes.includes(europe) && q.Region === "e")
      || (this.state.selectedGameModes.includes(us_australia) && q.Region === "us/a")
    );
    this.setState({
      startGame: true,
      questions: [...filtered]
    }, () => {
      this.getNextQuestion(() => this.loadNextQuestion());
    });
  }

  getNextQuestion(loadNextQuestion) {
    const noOfQs = this.state.questions.length;
    let chosenQ;
    do {
      chosenQ = Math.floor(Math.random() * noOfQs);
    } while(this.state.usedQuestions.includes(chosenQ));

    let filteredForAnswers = this.state.questions.filter(q => q.Manufacturer === this.state.questions[chosenQ].Manufacturer);
    if (filteredForAnswers.length < 4) {
      filteredForAnswers = this.state.questions.filter(q => q.Region === this.state.questions[chosenQ].Region);
    }
    let ans1;
    let ans2;
    let ans3;
    do {
      ans1 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans1].Model === this.state.questions[chosenQ].Model);
    do {
      ans2 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans2].Model === this.state.questions[chosenQ].Model
            || filteredForAnswers[ans2].Model === filteredForAnswers[ans1].Model);
    do {
      ans3 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans3].Model === this.state.questions[chosenQ].Model
            || filteredForAnswers[ans3].Model === filteredForAnswers[ans2].Model
            || filteredForAnswers[ans3].Model === filteredForAnswers[ans1].Model);

    const answers = [
      toLowerCaseExceptFirst(this.state.questions[chosenQ].Manufacturer) + " " + this.state.questions[chosenQ].Model,
      toLowerCaseExceptFirst(filteredForAnswers[ans1].Manufacturer) + " " + filteredForAnswers[ans1].Model,
      toLowerCaseExceptFirst(filteredForAnswers[ans2].Manufacturer) + " " + filteredForAnswers[ans2].Model,
      toLowerCaseExceptFirst(filteredForAnswers[ans3].Manufacturer) + " " + filteredForAnswers[ans3].Model
    ];
    
    const beforeImage = new Image();
    beforeImage.src = `/imgs/cars/before/${this.state.questions[chosenQ].BeforeImageName}`;

    const afterImage = new Image();
    afterImage.src = `/imgs/cars/after/${this.state.questions[chosenQ].AfterImageName}`;

    this.setState({
      nextQuestion: {
        value: toLowerCaseExceptFirst(this.state.questions[chosenQ].Manufacturer) + " " + this.state.questions[chosenQ].Model,
        afterImage: afterImage,
        afterImageName: this.state.questions[chosenQ].AfterImageName,
        beforeImage: beforeImage,
        beforeImageName: this.state.questions[chosenQ].BeforeImageName,
        answers: shuffle(answers),
        correct: false,
        selected: ""
      },
      usedQuestions: this.state.usedQuestions.length < this.state.questions.length - 1 ? [
        ...this.state.usedQuestions,
        chosenQ
      ] : [chosenQ]
    }, () => {
      if(loadNextQuestion) loadNextQuestion();
    });
  }

  loadNextQuestion() {
    this.setState({
      question: {
        ...this.state.nextQuestion
      }
    });
  }

  checkAnswer(event) {
    const isAnsweredCorrectlly = this.state.question.value === event.target.id;
    this.getNextQuestion();

    if(isAnsweredCorrectlly) {
      if(this.props.isSoundOn) this.correctAnswerSound.play();
      this.props.addWaterDonated(5);
      this.setState({ score: this.state.score + 1 });
      ReactGA.event({
        category: 'Cars',
        action: 'Answered correctly'
      });
    } else {
      if(this.props.isSoundOn) this.wrongAnswerSound.play();
      this.props.addQuestionAnswered();
      ReactGA.event({
        category: 'Cars',
        action: 'Answered incorrectly'
      });
    }

    postData('/api/addanswer', {
      UserID: this.props.userId,
      Category: "cars",
      BeforeImage: this.state.question.beforeImageName,
      AfterImage: this.state.question.afterImageName,
      IsCorrect: isAnsweredCorrectlly ? 1 : 0
    });

    this.setState({
      backImage: this.state.question.afterImage.src,
      question: {
        ...this.state.question,
        selected: event.target.id,
        correct: this.state.question.value
      }
    }, () => {
      setTimeout(() => {
        this.setState({
          isAnswered: true
        });
      }, 50);
    });

    setTimeout(() => {
      this.setState({ loadNextImage: true });
    }, 250);

    setTimeout(() => {
      this.loadNextQuestion();
      this.setState({ isAnswered: false, loadNextImage: false });
    }, nextQuestionTimeout * 1000);
  }

  clearScore() {
    this.setState({ score: 0 });
  }

  render() {
    return(
      <div className="cars">
        {
          this.props.windowSize.width < mobileBreakpoint ? <MobileHomeIcon history={ this.props.history } /> : null
        }
        { 
          this.state.startGame
          ? 
          <div>
            <GamePanel
              front={ <div className="img-div" style={{ backgroundImage: `url(${this.state.loadNextImage ? this.state.nextQuestion.beforeImage.src : this.state.question.beforeImage.src})` }}></div> }
              back={ <div className="img-div" style={{ backgroundImage: `url(${this.state.backImage})` }}></div> }
              isAnswered={ this.state.isAnswered }
              isFlipped={ this.state.isAnswered }
              question={ this.state.question }
              checkAnswer={ event => this.checkAnswer(event) }
              isMobile={ this.props.windowSize.width < mobileBreakpoint }
              hideLogo={ () => this.props.hideLogo() }
              showLogo={ () => this.props.showLogo() }
              setInGame = { value => this.props.setInGame(value) }
              bottleImgNo={ this.props.bottleImgNo }
              waterDonatedAmount={ this.props.waterDonatedAmount }
              windowSize={ this.props.windowSize }
              correctAnswers={ this.props.correctAnswers }
              numberOfQuestions={ this.props.numberOfQuestions }
              moduleName="Cars"
              addWaterDonated={ this.props.addWaterDonated }
              clearScore={ () => this.clearScore() }
              score={ this.state.score }
              userId={ this.props.userId }
            />
          </div>
          :
          <div>
            {
              this.props.windowSize.width < mobileBreakpoint ? <MobileHomeIcon history={ this.props.history } /> : null
            }
            <Tagline windowSize={ this.props.windowSize } />
            <div className="cars-menu-wrap">
              <NoScrollPanel title="Select Your Regions" showPlayButton={ true } playButtonOnClick={ () => this.startGame() } playButtonDisabled={ this.state.selectedGameModes.length === 0 }>
                <div className="cars-menu">
                  <div
                    id={ allModes }
                    className={ 
                      this.state.selectedGameModes.includes(asia)
                      && this.state.selectedGameModes.includes(europe)
                      && this.state.selectedGameModes.includes(us_australia)
                      ? "cars-menu-item selected" 
                      : "cars-menu-item"
                    }
                    onClick={
                      () => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleAll();
                      }
                    }
                  >
                    <p>ALL</p>
                  </div>
                  <div
                    id={ asia }
                    className={ this.state.selectedGameModes.includes(asia) ? "cars-menu-item selected" : "cars-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ asia }>ASIA</p>
                  </div>
                  <div
                    id={ europe }
                    className={ this.state.selectedGameModes.includes(europe) ? "cars-menu-item selected" : "cars-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ europe }>EUROPE</p>
                  </div>
                  <div
                    id={ us_australia }
                    className={ this.state.selectedGameModes.includes(us_australia) ? "cars-menu-item selected" : "cars-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ us_australia }>US/Australia</p>
                  </div>
                </div>
              </NoScrollPanel>
              <PlayButton onClick={ () => this.startGame() } disabled={ this.state.selectedGameModes.length === 0 } buttonSound={ this.props.buttonSound } isSoundOn={ this.props.isSoundOn } />
            </div>
          </div>
        }
      </div>
    );
  }
}