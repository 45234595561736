import React from 'react';
import taglineDesktop from '../../assets/tagline-desktop.png';
import taglineMobile from '../../assets/tagline-mobile.png';
import TextPanel from '../../components/text-panel/text-panel';
import { mobileBreakpoint } from '../../const';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

export class PrivacyPolicy extends React.Component {
  componentDidMount() {
    ReactGA.set({ page: "/privacy-policy" });
    ReactGA.pageview("/privacy-policy");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    this.props.setIsLoading(false);
  }

  render() {
    return (
      <div>
        {
          this.props.windowSize.width > mobileBreakpoint ?
            <div className={ this.props.windowSize.width > mobileBreakpoint ? "tagline" : "tagline mobile" }>
              <img src={ this.props.windowSize.width > mobileBreakpoint ? taglineDesktop : taglineMobile } alt="Play with purpose - donate water with every correct answer!" />
            </div>
          : null
        }
        <TextPanel
          title="privacy policy"
          isMobile={ this.props.windowSize.width < mobileBreakpoint }
          history={ this.props.history }
        >
          <p><b>TheGameWell™
          <br />PRIVACY POLICY
          <br />Effective Date: May 11, 2020</b></p>
          <br />
          <p>TheGameWell™ (“TheGameWell,” “We,” “Us,” “Our”) is committed to protecting the personal information You (“You,” or a “user”) share and/or store with Us. This Privacy Policy applies to transactions and activities and data gathered through the TheGameWell Website and interaction You may have with its related Social Media accounts.</p>
          <p>For questions concerning this Privacy Policy, or Our use of Your personal information, cookies, or similar technologies, please contact us at <a href="mailto:info@thegamewell.com">info@thegamewell.com</a></p>
          <p>Our Services comprise, generally, a matching exercise whereby you identify an image and select a text-based option that best matches this image. This Privacy Policy does not address, and We are not responsible for, the privacy, information, or other practices of any third parties, including any third party which We recommend or refer You to, or any third party operating any website to which this Website contains a link. The inclusion of a link on this Website does not imply endorsement of the linked website by Us or by Our affiliates.</p>
          
          <h2>Personally Identifiable Information that You Provide to Us</h2>
          <p>Generally, We may collect and use personal information for many purposes, including, but not limited to: service fulfillment, understanding and adapting to customer needs, providing referrals to third party product or service providers, providing a better website, improving Our services, and communicating with Our users regarding Our products and services with third party products and services.</p>
          <p>We do not collect personally identifiable information e.g., name, e-mail address, contact or similar information unless you choose to provide it to us. If You voluntarily provide Us with personal information, for example by sending an e-mail or by filling out a form and submitting it through Our Website, We may use that information only to respond to your message and to help us provide You with the information or services that You request.</p>
          
          <p><b>Account Information</b><br />Use of Our Website and Services may require that You disclose certain personal information for identification and added features, including demographic information (including, for example, ZIP code, age, gender).</p>
          <p>In some instances, use of Our Website and Services may require that You disclose a unique email address and/or individualized login credentials (e.g. Facebook / Google Plus) to register an account on Our Website.</p>
          
          <h2>How this Information is Used</h2>
          <p>We use the information We collect to provide, analyze, administer, enhance, and personalize Our Services and marketing efforts. Where applicable, We may also use the information we collect to process Your registration, any orders and payments You make, and to communicate with You on these and other topics. For example, We use the information we collect for:</p>
          <ul>
            <li>Customize our service to match Your experience level and/or previous interactions with the website.</li>
            <li>To customize Your experience by allowing advertising to be targeted to the users for whom such advertising is most pertinent.</li>
          </ul>
          <p><b>Direct Communication</b><br />We may periodically use Your information to communicate with You concerning Our Service (for example, by email, push notification and text messaging) so that We can send You news about Our Service, details about new features and content on Our Website, and special offers, promotional announcements (including those generated by third party product or service providers), and user surveys.</p>
          
          <h2>Non-Personal Information Collected Through Technology and Third Parties</h2>
          
          <p>During Your visit as You browse through the Website or read pages, We automatically collect and store the following anonymous information about Your visit:</p>
          <ul>
            <li>the date and time You access our site;</li>
            <li>the pages You visit on our site;</li>
            <li>if You navigate to our site by clicking a link, the location of that link;</li>
            <li>the technical capabilities of the computer you use to access our site;</li>
            <li>the internet service provider You use to connect to our site (for example "companyX.com" if You have a commercial internet account, or "universityX.edu" if You connect from a university); and</li>
            <li>the IP address (a number automatically assigned to Your computer whenever You are surfing the internet) from which You access our site.</li>
          </ul>
          <p>We use this information, in the aggregate, to make our website more useful to visitors — to learn about the number of visitors to our site and the types of technology used, to detect operational problems, and to improve the website's overall security.</p>
          <p><b>Cookies, Beacons, Local Storage and Other Similar Technologies</b><br />We use "cookies," Web beacons, HTML5 local storage and other similar technologies. These technologies allow us to manage access to and use of the Services, recognize you and provide personalization, and help us understand how people use the Our Services. You may not be able to access certain areas of Our websites, including TheGameWell.com and any third party product or service providers referred by Us, if Your computer does not accept cookies from Us.</p>
          <p>We do not respond to browser-based "do not track" signals. </p>
          <p>We may transmit non-personally identifiable website usage information to third parties in order to show you advertising for TheGameWell when you visit other sites.</p>
          
          <p><b>Analytics, Log Files and Reading History</b><br />We gather certain information automatically and store it in log files. This information may include IP addresses, browser type, operating system and other usage information about the use of Our Services, including a history of the pages You view.</p>
          <p>We may combine this automatically collected log information with other information We collect about You. We do this to improve services we offer You, including customized recommendations, advertising, to improve marketing, and to track access and use of Our Services across the devices that You may use to access Our Services.</p>
          <p>We have hired third parties to provide us information, reports and analysis about the usage, browsing patterns of our users. They may independently record the type of device and operating system You are using, general location information, as well as events that occur on Our Website, such as how often you use Our Website.</p>
          
          <h2>Third Party Persons or Entities with Whom We Share Personally Identifiable Information</h2>
          
          <p><b>We do not sell, rent, swap or authorize any third party to use your email address.</b></p>
          <p><b>Third Parties</b><br />We may share information about our audience in aggregate form. Nothing in this Privacy Policy is intended to indicate a restriction of Our use or sharing of aggregated information in any way.</p>
          <p>For instance, we may hire third parties to analyze Our users’ behavior, tastes and interests as submitted by You and other users’ on this Website. Similarly, we may use such data in combination with the personal information that you provide Us with to predictively tailor Our Services, including recommendations and third party referrals, to your particular tastes, interests, and preferences. </p>
          <p><b>Disclosure by Necessity</b><br />We may occasionally access, preserve, and/or disclose personal information as required by law, for example, to comply with a court order or subpoena or we have a good faith belief that such action is necessary to</p>
          <ul>
            <li>comply with the law or with legal process; </li>
            <li>protect and defend our rights and property; </li>
            <li>protect against misuse or unauthorized use of Our Services; or </li>
            <li>protect the personal safety or property of our users or the public (among other things, this means that if you provide false information or attempt to pose as someone else, information about you may be disclosed as part of any investigation into your actions).</li>
          </ul>
          
          <p>As we continue to develop our business, We or Our affiliates may sell or buy other businesses or entities, or we may merge with another company, or be bought by another company. In such transactions, personal information may be among the transferred assets.</p>
          <p>Your information may be stored and processed in any country in which we maintain facilities or conduct operations. By using our Website, your personal information may be transferred outside of your country of use and/or residence.</p>
          <p>When your account becomes inactive, your information may remain in our computers, electronic databases, and archives, and may not be reasonably capable of expungement.</p>
          
          <h2>Miscellaneous</h2>

          <p><b>Children's Guidelines</b></p>
          <p><b>COPPA Compliance.</b> We do not knowingly collect or store any personal information about children under the age of 13.</p>
          <p><b>Amendments</b><br />This Privacy Policy may be amended by Us at any time and without notice, but only by amending this Policy as posted on this Website. Any amendments will become effective 30 days after being posted on the website, unless circumstances require that a change be immediately implemented.</p>
          <br />
          <br />
        </TextPanel>
      </div>
    );
  };
}
  
export default withRouter(PrivacyPolicy);
