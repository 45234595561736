import React from 'react';
import exitIcon from '../../assets/exit-icon.png';
import './mobile-exit-icon.css';

export const MobileExitIcon = props => {
  const handleClick = () => props.history.push('/');
  return(
    <div
      className="mobile-exit-icon"
      onClick={ props.onClick || handleClick }
      style={ props.style }
    >
      <img src={ exitIcon } alt="Exit icon" />
    </div>
  );
}

export default MobileExitIcon;