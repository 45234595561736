import React from 'react';
import './text-panel.css';
import MobileExitIcon from '../mobile-exit-icon/mobile-exit-icon';

export const TextPanel = props => {
  return (
    <div className={ props.isMobile ? "panel mobile-panel" : "panel"}>
      <MobileExitIcon history={ props.history } style={{ paddingTop: "2%", paddingRight: "3%", transform: "scale(0.8)" }} />
      <h1>{ props.title }</h1>
      
      <div className="content-wrap">
        <div className="content-bg"></div>
        <div className="panel-content-container">
          <div className="panel-content">
            { props.children }
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextPanel;