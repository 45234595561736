import React from 'react';
import './mtg.css';
import Tagline from '../../components/tagline/tagline';
import NoScrollPanel from '../../components/no-scroll-panel/no-scroll-panel';
import GamePanel from '../../components/game-panel/game-panel';
import { shuffle, parseCvsAsync, postData } from '../../components/helpers/helpers';
import { nextQuestionTimeout, mobileBreakpoint } from '../../const';
import MobileHomeIcon from '../../components/mobile-home-icon/mobile-home-icon';
import mtgQuestions from '../../assets/mtg-questions.csv';
import PlayButton from '../../components/play-button/play-button';
import ReactGA from 'react-ga';
import CorrectAnswerSound from '../../assets/correct-answer-sound.mp3';
import WrongAnswerSound from '../../assets/wrong-answer-sound.mp3';

const allModes = "_allModes";
const standard = "_standard";
const modern = "_modern";
const vintage = "_vintage";
const legacy = "_legacy";
const oldSchool = "_oldSchool";

export default class Mtg extends React.Component {
  correctAnswerSound;
  wrongAnswerSound;

  constructor(props) {
    super(props);

    this.state = {
      selectedGameModes: [],
      startGame: false,
      isAnswered: false,
      questions: null,
      loadNextImage: false,
      backImage: null,
      usedQuestions: [],
      question: {
        value: "",
        beforeImage: "",
        beforeImageName: "",
        afterImage: "",
        afterImageName: "",
        answers: [],
        correct: false,
        selected: ""
      },
      nextQuestion: {
        value: "",
        img: "",
        afterImage: "",
        answers: [],
        correct: false,
        selected: ""
      },
      score: 0
    }
  }

  componentDidMount() {
    ReactGA.set({ page: "/mtg" });
    ReactGA.pageview("/mtg");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    if(localStorage.getItem("mtg-questions")) {
      this.setState({
        questions: JSON.parse(localStorage.getItem("mtg-questions"))
      });
    } else {
      parseCvsAsync(mtgQuestions).then(questions => {
        try {
          localStorage.setItem("mtg-questions", JSON.stringify(questions));
        } catch {} 
        finally {
          this.setState({
            questions: questions
          });
        }
      });
    }

    // preloading
    this.correctAnswerSound = new Audio(CorrectAnswerSound);
    this.wrongAnswerSound = new Audio(WrongAnswerSound);

    this.props.setIsLoading(false);
  }

  toggleSelectedMode(event) {
    if(this.state.selectedGameModes.includes(event.target.id)) {
      let slecetedGameMods = [...this.state.selectedGameModes];
      slecetedGameMods.splice(slecetedGameMods.findIndex(el => el === event.target.id), 1);

      this.setState({
        selectedGameModes: [...slecetedGameMods]
      });
    } else {
      this.setState({
        selectedGameModes: [
          ...this.state.selectedGameModes,
          event.target.id
        ]
      });
    }
  }

  toggleAll() {
    if(this.state.selectedGameModes.length === 5) {
      this.setState({
        selectedGameModes: []
      });
    } else {
      this.setState({
        selectedGameModes: [standard, modern, vintage, legacy, oldSchool]
      });
    }
  }

  startGame() {
    const filtered = this.state.questions?.filter(q => 
      (this.state.selectedGameModes.includes(standard) && q.s === "t")
      || (this.state.selectedGameModes.includes(modern) && q.m === "t")
      || (this.state.selectedGameModes.includes(vintage) && q.v === "t")
      || (this.state.selectedGameModes.includes(legacy) && q.l === "t")
      || (this.state.selectedGameModes.includes(oldSchool) && q.os === "t")
    );
    this.setState({
      startGame: true,
      questions: [...filtered]
    }, () => {
      this.getNextQuestion(() => this.loadNextQuestion());
    });
  }

  getNextQuestion(loadNextQuestion) {
    const noOfQs = this.state.questions.length;
    let chosenQ;
    do {
      chosenQ = Math.floor(Math.random() * noOfQs);
    } while(this.state.usedQuestions.includes(chosenQ));

    let filteredForAnswers = this.state.questions.filter(q => q.t === this.state.questions[chosenQ].t
                                                      && q.s === this.state.questions[chosenQ].s
                                                      && q.m === this.state.questions[chosenQ].m
                                                      && q.v === this.state.questions[chosenQ].v
                                                      && q.l === this.state.questions[chosenQ].l
                                                      && q.os === this.state.questions[chosenQ].os
                                                      && q.c === this.state.questions[chosenQ].c
                                                      && q.e === this.state.questions[chosenQ].e
                                                  );
    if (filteredForAnswers.length < 40) {
      filteredForAnswers = this.state.questions.filter(q => q.t === this.state.questions[chosenQ].t
                                                      && q.s === this.state.questions[chosenQ].s
                                                      && q.m === this.state.questions[chosenQ].m
                                                      && q.v === this.state.questions[chosenQ].v
                                                      && q.l === this.state.questions[chosenQ].l
                                                      && q.os === this.state.questions[chosenQ].os
                                                      && q.c === this.state.questions[chosenQ].c
                                                    );
      if (filteredForAnswers.length < 40) {
        filteredForAnswers = this.state.questions.filter(q => q.s === this.state.questions[chosenQ].s
                                                      && q.m === this.state.questions[chosenQ].m
                                                      && q.v === this.state.questions[chosenQ].v
                                                      && q.l === this.state.questions[chosenQ].l
                                                      && q.os === this.state.questions[chosenQ].os
                                                      && q.c === this.state.questions[chosenQ].c
                                                    );
        if (filteredForAnswers.length < 40) {
          filteredForAnswers = this.state.questions.filter(q => q.s === this.state.questions[chosenQ].s
                                                        && q.m === this.state.questions[chosenQ].m
                                                        && q.v === this.state.questions[chosenQ].v
                                                        && q.l === this.state.questions[chosenQ].l
                                                        && q.os === this.state.questions[chosenQ].os
                                                      );
          if (filteredForAnswers.length < 40) {
            filteredForAnswers = this.state.questions.filter(q => (q.s !== "t" || q.s === this.state.questions[chosenQ].s)
                                                          && (q.m !== "t" || q.m === this.state.questions[chosenQ].m)
                                                          && (q.v !== "t" || q.v === this.state.questions[chosenQ].v)
                                                          && (q.l !== "t" || q.l === this.state.questions[chosenQ].l)
                                                          && (q.os !== "t" || q.os === this.state.questions[chosenQ].os)
                                                        );
          }
        }
      }
    }
    let ans1;
    let ans2;
    let ans3;
    do {
      ans1 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans1].cn === this.state.questions[chosenQ].cn);
    do {
      ans2 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans2].cn === this.state.questions[chosenQ].cn
            || filteredForAnswers[ans2].cn === filteredForAnswers[ans1].cn);
    do {
      ans3 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans3].cn === this.state.questions[chosenQ].cn
            || filteredForAnswers[ans3].cn === filteredForAnswers[ans2].cn
            || filteredForAnswers[ans3].cn === filteredForAnswers[ans1].cn);

    const answers = [
      this.state.questions[chosenQ].cn,
      filteredForAnswers[ans1].cn,
      filteredForAnswers[ans2].cn,
      filteredForAnswers[ans3].cn
    ];
    
    const beforeImage = new Image();
    beforeImage.src = `/imgs/mtg/before/${this.state.questions[chosenQ].bi}`;

    const afterImage = new Image();
    afterImage.src = `/imgs/mtg/after/${this.state.questions[chosenQ].ai}`;

    this.setState({
      nextQuestion: {
        value: this.state.questions[chosenQ].cn,
        afterImage: afterImage,
        afterImageName: this.state.questions[chosenQ].ai,
        beforeImage: beforeImage,
        beforeImageName: this.state.questions[chosenQ].bi,
        answers: shuffle(answers),
        correct: false,
        selected: ""
      },
      usedQuestions: this.state.usedQuestions.length < this.state.questions.length - 1 ? [
        ...this.state.usedQuestions,
        chosenQ
      ] : [chosenQ]
    }, () => {
      if(loadNextQuestion) loadNextQuestion();
    });
  }

  loadNextQuestion() {
    this.setState({
      question: {
        ...this.state.nextQuestion
      }
    });
  }

  checkAnswer(event) {
    const isAnsweredCorrectlly = this.state.question.value === event.target.id;
    this.getNextQuestion();

    if(isAnsweredCorrectlly) {
      if(this.props.isSoundOn) this.correctAnswerSound.play();
      this.props.addWaterDonated(5);
      this.setState({ score: this.state.score + 1 });
      ReactGA.event({
        category: 'MTG',
        action: 'Answered correctly'
      });
    } else {
      if(this.props.isSoundOn) this.wrongAnswerSound.play();
      this.props.addQuestionAnswered();
      ReactGA.event({
        category: 'MTG',
        action: 'Answered incorrectly'
      });
    }

    postData('/api/addanswer', {
      UserID: this.props.userId,
      Category: "mtg",
      BeforeImage: this.state.question.beforeImageName,
      AfterImage: this.state.question.afterImageName,
      IsCorrect: isAnsweredCorrectlly ? 1 : 0
    });

    this.setState({
      backImage: this.state.question.afterImage.src,
      question: {
        ...this.state.question,
        selected: event.target.id,
        correct: this.state.question.value
      }
    }, () => {
      setTimeout(() => {
        this.setState({
          isAnswered: true
        });
      }, 50);
    });

    setTimeout(() => {
      this.setState({ loadNextImage: true });
    }, 250);

    setTimeout(() => {
      this.loadNextQuestion();
      this.setState({ isAnswered: false, loadNextImage: false });
    }, nextQuestionTimeout * 1000);
  }

  clearScore() {
    this.setState({ score: 0 });
  }

  render() {
    return(
      <div className="mtg">
        {
          this.props.windowSize.width < mobileBreakpoint ? <MobileHomeIcon history={ this.props.history } /> : null
        }
        { 
          this.state.startGame
          ? 
          <div>
            <GamePanel
              front={ <div className="img-div" style={{ backgroundImage: `url(${this.state.loadNextImage ? this.state.nextQuestion.beforeImage.src : this.state.question.beforeImage.src})` }}></div> }
              back={ <div className="img-div" style={{ backgroundImage: `url(${this.state.backImage})` }}></div> }
              isAnswered={ this.state.isAnswered }
              isFlipped={ this.state.isAnswered }
              question={ this.state.question }
              checkAnswer={ event => this.checkAnswer(event) }
              isMobile={ this.props.windowSize.width < mobileBreakpoint }
              hideLogo={ () => this.props.hideLogo() }
              showLogo={ () => this.props.showLogo() }
              setInGame = { value => this.props.setInGame(value) }
              bottleImgNo={ this.props.bottleImgNo }
              waterDonatedAmount={ this.props.waterDonatedAmount }
              windowSize={ this.props.windowSize }
              correctAnswers={ this.props.correctAnswers }
              numberOfQuestions={ this.props.numberOfQuestions }
              moduleName="Mtg"
              addWaterDonated={ this.props.addWaterDonated }
              clearScore={ () => this.clearScore() }
              score={ this.state.score }
              userId={ this.props.userId }
            />
          </div>
          :
          <div>
            {
              this.props.windowSize.width < mobileBreakpoint ? <MobileHomeIcon history={ this.props.history } /> : null
            }
            <Tagline windowSize={ this.props.windowSize } />
            <div className="mtg-menu-wrap">
              <NoScrollPanel title="Select Your Formats" showPlayButton={ true } playButtonOnClick={ () => this.startGame() } playButtonDisabled={ this.state.selectedGameModes.length === 0 }>
                <div className="mtg-menu">
                  <div
                    id={ allModes }
                    className={ 
                      this.state.selectedGameModes.includes(standard)
                      && this.state.selectedGameModes.includes(modern)
                      && this.state.selectedGameModes.includes(vintage)
                      && this.state.selectedGameModes.includes(legacy)
                      && this.state.selectedGameModes.includes(oldSchool)
                      ? "mtg-menu-item selected" 
                      : "mtg-menu-item"
                    }
                    onClick={
                      () => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleAll();
                      }
                    }
                  >
                    <p>EDH</p>
                  </div>
                  <div
                    id={ standard }
                    className={ this.state.selectedGameModes.includes(standard) ? "mtg-menu-item selected" : "mtg-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ standard }>STANDARD</p>
                  </div>
                  <div
                    id={ modern }
                    className={ this.state.selectedGameModes.includes(modern) ? "mtg-menu-item selected" : "mtg-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ modern }>MODERN</p>
                  </div>
                  <div
                    id={ vintage }
                    className={ this.state.selectedGameModes.includes(vintage) ? "mtg-menu-item selected" : "mtg-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ vintage }>VINTAGE</p>
                  </div>
                  <div
                    id={ legacy }
                    className={ this.state.selectedGameModes.includes(legacy) ? "mtg-menu-item selected" : "mtg-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ legacy }>LEGACY</p>
                  </div>
                  <div
                    id={ oldSchool }
                    className={ this.state.selectedGameModes.includes(oldSchool) ? "mtg-menu-item selected" : "mtg-menu-item"}
                    onClick={
                      event => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.toggleSelectedMode(event);
                      }
                    }
                  >
                    <p id={ oldSchool }>OLD SCHOOL</p>
                  </div>
                </div>
              </NoScrollPanel>
              <PlayButton onClick={ () => this.startGame() } disabled={ this.state.selectedGameModes.length === 0 } buttonSound={ this.props.buttonSound } isSoundOn={ this.props.isSoundOn } />
            </div>
          </div>
        }
      </div>
    );
  }
}