import React from 'react';
import styles from './leaderboard-small-desktop.module.css';
import { withRouter } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg';

const LeaderboardSmallDesktop = props => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState();

  React.useEffect(() => {
    fetch("/api/get-small-leaderboard-data")
    .then(response => response.json())
    .then(result => {
      setIsLoading(false);
      if (result.success) {
        setData(result.data);
      }
    })
    .catch(() => setIsLoading(false));
  }, []);

  return (
    <div
      className={ styles.leaderboardWrap }
      onClick={ () => {
        props.history.push("/leaderboard");
        if (props.isSoundOn) props.buttonSound.play();
      }}
    >
      <div className={ `view-leaderboard-button ${styles.leaderboardButton}` }>
        <PlusIcon />
        <p><span className="orange">VIEW</span> LEADERBOARD</p>
      </div>
      <div className={ styles.header }>
        <p>All Time</p>
        <p>Past 30 Days</p>
        <p>Past 5 Days</p>
      </div>
      <div className={ styles.content }>
        {
          !isLoading ? 
            <>
              <div className={ styles.row }>
                <p>{ data?.bestAllTime ? "1." : null }</p>
                <p>{ data?.bestAllTime ? data.bestAllTime.username : "No data" }</p>
                <p>{ data?.bestAllTime ? data.bestAllTime.donated.toLocaleString() + " cups" : null }</p>
              </div>
              <div className={ styles.row }>
                <p>{ data?.best30Day ? "1." : null }</p>
                <p>{ data?.best30Day ? data.best30Day.username : "No data" }</p>
                <p>{ data?.best30Day ? data.best30Day.donated.toLocaleString() + " cups" : null }</p>
              </div>
              <div className={ styles.row }>
                <p>{ data?.best5Day ? "1." : null }</p>
                <p>{ data?.best5Day ? data.best5Day.username : "No data" }</p>
                <p>{ data?.best5Day ? data.best5Day.donated.toLocaleString() + " cups" : null }</p>
              </div>
            </>
          :
            <>
              <div className={ `loader ${styles.loader}` }></div>
            </>
        }
      </div>
    </div>
  );
};

export default withRouter(LeaderboardSmallDesktop);