import React from 'react';
import './my-account.css';
import taglineDesktop from '../../assets/tagline-desktop.png';
import taglineMobile from '../../assets/tagline-mobile.png';
import { mobileBreakpoint } from '../../const';
import { withRouter } from 'react-router-dom';
import userIcon from '../../assets/user-icon.png';
import ReactGA from 'react-ga';
import MobileExitIcon from '../../components/mobile-exit-icon/mobile-exit-icon';

export class MyAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      error: ""
    };
  }

  componentDidMount() {
    ReactGA.set({ page: "/my-account" });
    ReactGA.pageview("/my-account");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    this.props.setIsLoading(false);
  }

  handleLogin() {
    if(this.props.isSoundOn) this.props.buttonSound.play();
    if (this.state.username && this.state.password && !this.state.isSubmitting) {
      this.setState({ isSubmitting: true });
      fetch("/api/authenticate", {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          Username: this.state.username,
          Password: this.state.password
        })
      })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.props.setUser(result.user);
          this.props.history.push("/");
        } else {
          this.setState({ error: result.error, isSubmitting: false });
        }
      })
      .catch(result => this.setState({ error: result.error ? result.error : "Incorrect username or password.", isSubmitting: false }));
    }
  }

  render() {
    return (
      <div>
        {
          this.props.windowSize.width > mobileBreakpoint ?
            <div className={ this.props.windowSize.width > mobileBreakpoint ? "tagline" : "tagline mobile" }>
              <img src={ this.props.windowSize.width > mobileBreakpoint ? taglineDesktop : taglineMobile } alt="Play with purpose - donate water with every correct answer!" />
            </div>
          : null
        }
        <div className={ `my-account-container ${this.props.isMobile ? "my-account-container-mobile" : ""}` } style={{ minHeight: `${this.props.minHeight*0.80}px` }}>
          <MobileExitIcon style={{ paddingRight: "1em", paddingTop: "1em" }} history={ this.props.history } />
          <div className="my-account-content">
            <img src={ userIcon } alt="user icon" />
            <h1>Your Profile</h1>
            <hr />
            <h1>{ this.props.user?.username }</h1>
            <p>You have donated { (+this.props.user?.totalDonated).toLocaleString() } cups of water.</p>
            {
              !this.props.user?.isFromFacebook ?
                <div className="edit-profile-button" onClick={ () => this.props.history.push("/edit-profile") }>
                  <p>Edit Profile</p>
                </div>
              : null
            }
            <div className="sign-out-button" onClick={ () => window.location.replace("/logout") }>
             <p>Sign Out</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(MyAccount);
