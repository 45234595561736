import React from 'react';
import GamePanel from '../../components/game-panel/game-panel';
import { shuffle, parseCvsAsync, postData } from '../../components/helpers/helpers';
import { nextQuestionTimeout, mobileBreakpoint } from '../../const';
import MobileHomeIcon from '../../components/mobile-home-icon/mobile-home-icon';
import dogsQuestions from '../../assets/dogs-questions.csv';
import ReactGA from 'react-ga';
import CorrectAnswerSound from '../../assets/correct-answer-sound.mp3';
import WrongAnswerSound from '../../assets/wrong-answer-sound.mp3';

export default class Dogs extends React.Component {
  correctAnswerSound;
  wrongAnswerSound;

  constructor(props) {
    super(props);

    this.state = {
      startGame: false,
      isAnswered: false,
      shouldFlip: false,
      isFlipped: false,
      questions: null,
      usedQuestions: [],
      question: {
        value: "",
        afterImage: "",
        answers: [],
        correct: false,
        selected: ""
      },
      nextQuestion: {
        value: "",
        afterImage: "",
        answers: [],
        correct: false,
        selected: ""
      },
      score: 0
    }
  }

  componentDidMount() {
    ReactGA.set({ page: "/dogs" });
    ReactGA.pageview("/dogs");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    parseCvsAsync(dogsQuestions).then(questions => {
      this.setState({
        questions: questions,
        startGame: true
      }, () => this.getNextQuestion(() => {
        
        this.loadNextQuestion();
        this.props.setIsLoading(false);
      }));
    });

    this.correctAnswerSound = new Audio(CorrectAnswerSound);
    this.wrongAnswerSound = new Audio(WrongAnswerSound);
  }

  getNextQuestion(loadNextQuestion) {
    const noOfQs = this.state.questions.length;
    let chosenQ;
    do {
      chosenQ = Math.floor(Math.random() * noOfQs);
    } while(this.state.usedQuestions.includes(chosenQ));

    const filteredForAnswers = this.state.questions.filter(q => q.Group === this.state.questions[chosenQ].Group);
    let ans1;
    let ans2;
    let ans3;
    do {
      ans1 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans1].Breed === this.state.questions[chosenQ].Breed);
    do {
      ans2 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans2].Breed === this.state.questions[chosenQ].Breed
            || filteredForAnswers[ans2].Breed === filteredForAnswers[ans1].Breed);
    do {
      ans3 = Math.floor(Math.random() * filteredForAnswers.length);
    } while(filteredForAnswers[ans3].Breed === this.state.questions[chosenQ].Breed
      || filteredForAnswers[ans3].Breed === filteredForAnswers[ans2].Breed
      || filteredForAnswers[ans3].Breed === filteredForAnswers[ans1].Breed);

    const answers = [
      this.state.questions[chosenQ].Breed,
      filteredForAnswers[ans1].Breed,
      filteredForAnswers[ans2].Breed,
      filteredForAnswers[ans3].Breed
    ];

    const image = new Image();
    image.src = `/imgs/dogs/${this.state.questions[chosenQ].ImageName}`;
    
    this.setState({
      nextQuestion: {
        value: this.state.questions[chosenQ].Breed,
        afterImage: image,
        afterImageName: this.state.questions[chosenQ].ImageName,
        answers: shuffle(answers),
        correct: false,
        selected: ""
      },
      usedQuestions: this.state.usedQuestions.length < this.state.questions.length - 1 ? [
        ...this.state.usedQuestions,
        chosenQ
      ] : [chosenQ]
    }, () => {
      if(loadNextQuestion) loadNextQuestion();
    });
  }

  loadNextQuestion() {
    this.setState({
      question: {
        ...this.state.nextQuestion
      },
      isAnswered: false
    }, () => this.getNextQuestion());
  }

  checkAnswer(event) {
    const isAnsweredCorrectlly = this.state.question.value === event.target.id;
    if(isAnsweredCorrectlly) {
      if(this.props.isSoundOn) this.correctAnswerSound.play();
      this.props.addWaterDonated(5);
      this.setState({ score: this.state.score + 1 });
      ReactGA.event({
        category: 'Dogs',
        action: 'Answered correctly'
      });
    } else {
      if(this.props.isSoundOn) this.wrongAnswerSound.play();
      this.props.addQuestionAnswered();
      ReactGA.event({
        category: 'Dogs',
        action: 'Answered incorrectly'
      });
    }

    postData('/api/addanswer', {
      UserID: this.props.userId,
      Category: "Dogs",
      AfterImage: this.state.question.afterImageName,
      IsCorrect: isAnsweredCorrectlly ? 1 : 0
    });

    this.setState({
      question: {
        ...this.state.question,
        selected: event.target.id,
        correct: this.state.question.value
      },
      isAnswered: true
    });
    
    setTimeout(() => {
      this.setState({ shouldFlip: !this.state.shouldFlip });
    }, nextQuestionTimeout * 1000);

    setTimeout(() => {
      this.setState({ isFlipped: !this.state.isFlipped });
      this.loadNextQuestion();
    }, (nextQuestionTimeout * 1000) + 250);
  }

  clearScore() {
    this.setState({ score: 0 });
  }

  render() {
    return(
      <div className="dogs">
        {
          this.props.windowSize.width < mobileBreakpoint ? <MobileHomeIcon history={ this.props.history } /> : null
        }
        <div>
          <GamePanel
            front={ <div className="img-div" style={{ backgroundImage: `url(${this.state.isFlipped ? this.state.nextQuestion.afterImage.src : this.state.question.afterImage.src})` }}></div> }
            back={ <div className="img-div" style={{ backgroundImage: `url(${this.state.isFlipped ? this.state.question.afterImage.src : this.state.nextQuestion.afterImage.src})` }}></div> }
            isAnswered={ this.state.isAnswered }
            isFlipped={ this.state.shouldFlip }
            question={ this.state.question }
            checkAnswer={ event => this.checkAnswer(event) }
            isMobile={ this.props.windowSize.width < mobileBreakpoint }
            windowSize={ this.props.windowSize }
            hideLogo={ () => this.props.hideLogo() }
            showLogo={ () => this.props.showLogo() }
            setInGame={ value => this.props.setInGame(value) }
            bottleImgNo={ this.props.bottleImgNo }
            waterDonatedAmount={ this.props.waterDonatedAmount }
            correctAnswers={ this.props.correctAnswers }
            numberOfQuestions={ this.props.numberOfQuestions }
            moduleName="Dogs"
            addWaterDonated={ this.props.addWaterDonated }
            clearScore={ () => this.clearScore() }
            score={ this.state.score }
            userId={ this.props.userId }
          />
        </div>
      </div>
    );
  }
}