import React from 'react';
import './create-account.css';
import taglineDesktop from '../../assets/tagline-desktop.png';
import taglineMobile from '../../assets/tagline-mobile.png';
import { mobileBreakpoint } from '../../const';
import { withRouter } from 'react-router-dom';
import userIcon from '../../assets/user-icon.png';
import ReactGA from 'react-ga';
import lockIcon from '../../assets/lock-icon.png';
import MobileExitIcon from '../../components/mobile-exit-icon/mobile-exit-icon';
import Reaptcha from 'reaptcha';
import { recaptchaSiteKey } from '../../const';
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg';

export class CreateAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      error: "",
      ageValid: false,
      isRecaptchaValid: false
    };
  }

  componentDidMount() {
    ReactGA.set({ page: "/create-account" });
    ReactGA.pageview("/create-account");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    this.props.setIsLoading(false);
  }

  handleRegistration() {
    if(this.props.isSoundOn) this.props.buttonSound.play();
    if (!this.state.username) {
      this.setState({ error: "Username is a required field." });
    } else if (!this.state.email) {
      this.setState({ error: "Email is a required field." });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)) {
      this.setState({ error: "Email is not valid." });
    } else if (!this.state.password) {
      this.setState({ error: "Password is a required field." });
    } else if (!this.state.confirmPassword) {
      this.setState({ error: "Confirm password is a required field." });
    } else if (this.state.confirmPassword !== this.state.password) {
      this.setState({ error: "Passwords do not match." });
    } else if (this.state.password.length < 6) {
      this.setState({ error: "Passwords must be at least 6 characters long." });
    } else if (this.state.ageValid !== "true") {
      console.log(this.state.ageValid);
      this.setState({ error: "You must be 13 or older to join." });
    } else if (!this.state.isRecaptchaValid) {
      this.setState({ error: "You have to solve the captcha" });
    } else if (!this.state.isSubmitting) {
      this.setState({ isSubmitting: true, error: "" });
      fetch("/api/register-user", {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          Email: this.state.email,
          Username: this.state.username,
          Password: this.state.password
        })
      })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.props.setUser(result.user);
          window.location.replace("/");
        } else {
          this.setState({ error: result.error, isSubmitting: false });
        }
      })
      .catch(result => this.setState({ error: result.error ? result.error : "Unexpected error has occurred. Please try again later.", isSubmitting: false }));
    }
    this.recaptchaRef.reset();
    this.setState({ isRecaptchaValid: false });
  }

  recaptchaRef;
  render() {
    return (
      <div>
        {
          this.props.windowSize.width > mobileBreakpoint ?
            <div className={ this.props.windowSize.width > mobileBreakpoint ? "tagline" : "tagline mobile" }>
              <img src={ this.props.windowSize.width > mobileBreakpoint ? taglineDesktop : taglineMobile } alt="Play with purpose - donate water with every correct answer!" />
            </div>
          : null
        }
        <div className={ `create-account-container ${this.props.isMobile ? "create-account-container-mobile" : ""}` } style={{ minHeight: `${this.props.minHeight*0.80}px` }}>
          <MobileExitIcon style={{ paddingRight: "1em", paddingTop: "1em" }} history={ this.props.history } />
          <div className="create-account-content">
            <h1>Create New Account</h1>
            <hr />
            <div className="choose-username">
              <div className="image-wrap" style={{ backgroundImage: `url(${userIcon})` }}></div>
              <p>Choose a Username:</p>
            </div>
            <div className="input">
              <input placeholder="Username" autoFocus value={ this.state.username } onChange={ e => this.setState({ username: e.target.value }) } autoComplet="username" />
            </div>
            <div className="input">
              <input placeholder="Your Email" value={ this.state.email } onChange={ e => this.setState({ email: e.target.value }) } autoComplet="email" />
            </div>
            <div className="choose-password">
              <div className="image-wrap lock" style={{ backgroundImage: `url(${lockIcon})` }}></div>
              <p>Choose a Password:</p>
            </div>
            <div className="input">
              <input placeholder="Password" type="password" value={ this.state.password } onChange={ e => this.setState({ password: e.target.value }) } autoComplete="current-password" />
            </div>
            <div className="input">
              <input placeholder="Confirm Password" type="password" value={ this.state.confirmPassword } onChange={ e => this.setState({ confirmPassword: e.target.value }) } />
            </div>
            <div className="age-verification">
              <p>Age Verification:</p>
              <div className="radio-input">
                <input id="yes" name="agevalid" type="radio" value={ true } onChange={ e => this.setState({ ageValid: e.target.value }) } />
                <label>Yes, I am 13 or older.</label>
              </div>
              <div className="radio-input">
                <input id="no" name="agevalid" type="radio" value={ false } onChange={ e => this.setState({ ageValid: e.target.value }) } />
                <label>No, I am under 13.</label>
              </div>
            </div>
            <div className="recaptcha-wrap">
              <Reaptcha ref={ e => this.recaptchaRef = e } sitekey={ recaptchaSiteKey } onVerify={ () => this.setState({ isRecaptchaValid: true }) } />
            </div>
            <p className="create-account-error">{ this.state.error }</p>
            <div className="create-account-button" onClick={ () => this.handleRegistration() }>
              <PlusIcon />
              <p>CREATE NEW ACCOUNT</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(CreateAccount);
