import React from 'react';
import './no-scroll-panel.css';

export const NoScrollPanel = props => {
  return (
    <div className="no-scroll-panel-wrap">
      <div className="no-scroll-panel" style={ props.style }>
        { props.title ? <h1>{ props.title }</h1> : null }
        { props.children }
      </div>
    </div>
  );
}

export default NoScrollPanel;