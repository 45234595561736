import React from 'react';
import './edit-profile.css';
import taglineDesktop from '../../assets/tagline-desktop.png';
import taglineMobile from '../../assets/tagline-mobile.png';
import lockIcon from '../../assets/lock-icon.png';
import { mobileBreakpoint } from '../../const';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import MobileExitIcon from '../../components/mobile-exit-icon/mobile-exit-icon';

export class EditProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      successMessage: "",
      error: ""
    };
  }

  componentDidMount() {
    ReactGA.set({ page: "/edit-profile" });
    ReactGA.pageview("/edit-profile");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    this.props.setIsLoading(false);
  }

  handlePasswordChange() {
    if (this.state.newPassword && this.state.confirmNewPassword && this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({ error: "Password do not match!" });
    } else if (!this.state.currentPassword) {
      this.setState({ error: "Current password is a required field." });
    } else if (!this.state.newPassword) {
      this.setState({ error: "New password is a required field." });
    } else if (!this.state.confirmNewPassword) {
      this.setState({ error: "Confirm new password is a required field." });
    } else {
      this.setState({ error: "", successMessage: "" });
      fetch("/api/update-password", {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          CurrentPassword: this.state.currentPassword,
          NewPassword: this.state.newPassword
        })
      })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.setState({ successMessage: "You have successfully changed your password." });
        } else {
          this.setState({ error: result.error });
        }
      })
      .catch(() => this.setState({ error: "Unexpected error has occurred. Please try again later." }));
    }
  }

  render() {
    return (
      <div>
        {
          this.props.windowSize.width > mobileBreakpoint ?
            <div className={ this.props.windowSize.width > mobileBreakpoint ? "tagline" : "tagline mobile" }>
              <img src={ this.props.windowSize.width > mobileBreakpoint ? taglineDesktop : taglineMobile } alt="Play with purpose - donate water with every correct answer!" />
            </div>
          : null
        }
        <div className={ `edit-profile-container ${this.props.isMobile ? "edit-profile-container-mobile" : ""}` } style={{ minHeight: `${this.props.minHeight*0.80}px` }}>
          <MobileExitIcon style={{ paddingRight: "1em", paddingTop: "1em" }} history={ this.props.history } />
          <div className="edit-profile-content">
            <h1>Edit Profile</h1>
            <hr />
            <p>Edit password</p>

            <div className="input">
              <input placeholder="Username" autoFocus value={ this.props.user?.username } disabled />
            </div>
            <div className="input">
              <input placeholder="Your Email" autoFocus value={ this.props.user?.email } disabled />
            </div>
            <div className="edit-password-text">
              <img src={ lockIcon } alt="lock icon" />
              <p>Edit Password</p>
            </div>
            <div className="input">
              <input placeholder="Current Password" type="password" value={ this.state.currentPassword } onChange={ e => this.setState({ currentPassword: e.target.value }) } autoComplete="current-password" />
            </div>
            <div className="input">
              <input placeholder="New Password" type="password" value={ this.state.newPassword } onChange={ e => this.setState({ newPassword: e.target.value }) } autoComplete="new-password" />
            </div>
            <div className="input">
              <input placeholder="Confirm New Password" type="password" value={ this.state.confirmNewPassword } onChange={ e => this.setState({ confirmNewPassword: e.target.value }) } />
            </div>
            { this.state.error ? <p className="error-message">{ this.state.error }</p> : null }
            { this.state.successMessage ? <p className="success-message">{ this.state.successMessage }</p> : null }

            <div className="save-changes-button" onClick={ () => this.handlePasswordChange() }>
              <p>SAVE CHANGES</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(EditProfile);
