import React from 'react';
import './play-button.css';

export const PlayButton = props => {
  return(
    <div
      className={`play-button-wrap ${!props.disabled ? "valid" : ""}`}
      onClick={
        () => {
          if(!props.disabled) {
            if(props.isSoundOn) props.buttonSound.play();
            props.onClick();
          }
        }
      }>
      <div className={`play-button ${!props.disabled ? "valid" : ""}`}>
        <p>PLAY</p>
      </div>
    </div>
  );
}

export default PlayButton;