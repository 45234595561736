import React from 'react';
import './main-menu.css';
import MoviesIcon from '../../assets/menu-icons/Movies.png';
import MtgIcon from '../../assets/menu-icons/Mtg.png';
import SportsIcon from '../../assets/menu-icons/Sports.png';
import TvIcon from '../../assets/menu-icons/Tv.png';
import AlbumsIcon from '../../assets/menu-icons/Albums.png';
import ArtIcon from '../../assets/menu-icons/Art.png';
import CarsIcon from '../../assets/menu-icons/Cars.png';
import DogsIcon from '../../assets/menu-icons/Dogs.png';
import { ReactComponent as ArrowLeft } from '../../assets/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { SwitchTransition, CSSTransition } from "react-transition-group";

export default class MainMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuPage: 1
    }
  }

  loadNext() {
    if (this.state.menuPage === 1) {
      this.setState({ menuPage: 2 });
    }
  }

  loadPrevious() {
    if (this.state.menuPage === 2) {
      this.setState({ menuPage: 1 });
    }
  }

  render() {
    return(
      <div className="main-menu">
        {
          this.props.isMobile ?
            <>
              <div className={`arrow left ${this.state.menuPage === 1 ? "inactive" : ""}`} onClick={ () => this.loadPrevious() }><ArrowLeft /></div>
              <SwitchTransition>
                <CSSTransition
                  key={ this.state.menuPage }
                  addEndListener={ (node, done) => node.addEventListener("transitionend", done, false) }
                  classNames={`${this.state.menuPage === 1 ? "transitionRight" : "transitionLeft"}`}
                >
                {
                  this.state.menuPage === 1 ?
                  
                  <div>
                    <div className="menu-row">
                      <div className="menu-item-bg">
                        <div
                          className="menu-item"
                          onClick={ () => {
                              if(this.props.isSoundOn) this.props.buttonSound.play();
                              this.props.history.push("/movies");
                            }
                          }
                        >
                          <img src={ MoviesIcon } alt="movies" />
                          <p>movies</p>
                        </div>
                      </div>
                      <div className="menu-item-bg">
                        <div
                          className="menu-item"
                          onClick={ () => {
                              if(this.props.isSoundOn) this.props.buttonSound.play();
                              this.props.history.push("/sports");
                            }
                          }
                        >
                          <img src={ SportsIcon } alt="sports" />
                          <p>sports</p>
                        </div>
                      </div>
                    </div>
                    <div className="menu-row">
                      <div className="menu-item-bg">
                        <div
                          className="menu-item"
                          onClick={ () => {
                              if(this.props.isSoundOn) this.props.buttonSound.play();
                              this.props.history.push("/tv");
                            }
                          }
                        >
                          <img src={ TvIcon } alt="tv" />
                          <p>tv</p>
                        </div>
                      </div>
                      <div
                        className="menu-item-bg"
                        onClick={ () => {
                            if(this.props.isSoundOn) this.props.buttonSound.play();
                            this.props.history.push("/albums");
                          }
                        }
                      >
                        <div className="menu-item">
                          <img src={ AlbumsIcon } alt="albums" />
                          <p>albums</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div>
                    <div className="menu-row">
                      <div className="menu-item-bg">
                        <div
                          className="menu-item art-icon"
                          onClick={ () => {
                              if(this.props.isSoundOn) this.props.buttonSound.play();
                              this.props.history.push("/art");
                            }
                          }
                        >
                          <img src={ ArtIcon } alt="art" />
                          <p>art</p>
                        </div>
                      </div>
                      <div className="menu-item-bg">
                        <div
                          className="menu-item"
                          onClick={ () => {
                              if(this.props.isSoundOn) this.props.buttonSound.play();
                              this.props.history.push("/dogs");
                            }
                          }
                        >
                          <img src={ DogsIcon } alt="dosg" />
                          <p>dogs</p>
                        </div>
                      </div>
                    </div>
                    <div className="menu-row">
                      <div className="menu-item-bg">
                        <div
                          className="menu-item"
                          onClick={ () => {
                              if(this.props.isSoundOn) this.props.buttonSound.play();
                              this.props.history.push("/cars");
                            }
                          }
                        >
                          <img src={ CarsIcon } alt="cars" />
                          <p>cars</p>
                        </div>
                      </div>
                      <div
                        className="menu-item-bg"
                        onClick={ () => {
                            if(this.props.isSoundOn) this.props.buttonSound.play();
                            this.props.history.push("/mtg");
                          }
                        }
                      >
                        <div className="menu-item mtg-icon">
                          <img src={ MtgIcon } alt="mtg" />
                          <p>magic the gathering</p>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                </CSSTransition>
              </SwitchTransition>
              <div className={`arrow right ${this.state.menuPage === 2 ? "inactive" : ""}`} onClick={ () => this.loadNext() }><ArrowRight /></div>
            </>
          :
          <div>
            <div>
              <div className="menu-row">
                <div className="menu-item-bg">
                  <div
                    className="menu-item"
                    onClick={ () => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.props.history.push("/movies");
                      }
                    }
                  >
                    <img src={ MoviesIcon } alt="movies" />
                    <p>movies</p>
                  </div>
                </div>
                <div className="menu-item-bg">
                  <div
                    className="menu-item"
                    onClick={ () => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.props.history.push("/sports");
                      }
                    }
                  >
                    <img src={ SportsIcon } alt="sports" />
                    <p>sports</p>
                  </div>
                </div>
                <div className="menu-item-bg">
                  <div
                    className="menu-item art-icon"
                    onClick={ () => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.props.history.push("/art");
                      }
                    }
                  >
                    <img src={ ArtIcon } alt="art" />
                    <p>art</p>
                  </div>
                </div>
                <div className="menu-item-bg">
                  <div
                    className="menu-item"
                    onClick={ () => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.props.history.push("/dogs");
                      }
                    }
                  >
                    <img src={ DogsIcon } alt="dogs" />
                    <p>dogs</p>
                  </div>
                </div>
              </div>
              <div className="menu-row">
                <div className="menu-item-bg">
                  <div
                    className="menu-item"
                    onClick={ () => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.props.history.push("/tv");
                      }
                    }
                  >
                    <img src={ TvIcon } alt="tv" />
                    <p>tv</p>
                  </div>
                </div>
                <div
                  className="menu-item-bg"
                  onClick={ () => {
                      if(this.props.isSoundOn) this.props.buttonSound.play();
                      this.props.history.push("/albums");
                    }
                  }
                >
                  <div className="menu-item">
                    <img src={ AlbumsIcon } alt="albums" />
                    <p>albums</p>
                  </div>
                </div>
                <div className="menu-item-bg">
                  <div
                    className="menu-item"
                    onClick={ () => {
                        if(this.props.isSoundOn) this.props.buttonSound.play();
                        this.props.history.push("/cars");
                      }
                    }
                  >
                    <img src={ CarsIcon } alt="cars" />
                    <p>cars</p>
                  </div>
                </div>
                <div
                  className="menu-item-bg"
                  onClick={ () => {
                      if(this.props.isSoundOn) this.props.buttonSound.play();
                      this.props.history.push("/mtg");
                    }
                  }
                >
                  <div className="menu-item mtg-icon">
                    <img src={ MtgIcon } alt="mtg" />
                    <p>magic the gathering</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}