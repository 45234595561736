import React from 'react';
import taglineDesktop from '../../assets/tagline-desktop.png';
import taglineMobile from '../../assets/tagline-mobile.png';
import TextPanel from '../../components/text-panel/text-panel';
import { mobileBreakpoint } from '../../const';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

export class About extends React.Component {
  componentDidMount() {
    ReactGA.set({ page: "/about" });
    ReactGA.pageview("/about");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    this.props.setIsLoading(false);
  }

  render() {
    return (
      <div>
        {
          this.props.windowSize.width > mobileBreakpoint ?
            <div className={ this.props.windowSize.width > mobileBreakpoint ? "tagline" : "tagline mobile" }>
              <img src={ this.props.windowSize.width > mobileBreakpoint ? taglineDesktop : taglineMobile } alt="Play with purpose - donate water with every correct answer!" />
            </div>
          : null
        }
        <TextPanel
          title="about"
          isMobile={ this.props.windowSize.width < mobileBreakpoint }
          history={ this.props.history }
        >
          <br />
          <h2>What is TheGameWell?</h2>
          <p>TheGameWell is a website built with a simple goal in mind: Play With Purpose. This idea is this: All of us spend an inordinate amount of time on our devices. Why not do a little good at the same time, and scratch that altruistic itch of yours? When you log onto our site, you’re presented with several game options. Each game has the same basic rule. See an image. See 4 choices. Pick the correct choice from the four that matches the image. Repeat. For each image that you identify correctly, TheGameWell donates the equivalent of 5 cups of water to those in need around the world.</p>

          <br />
          <h2>What’s this about donating water?</h2>
          <p>While you’re playing our game, you’ll notice a water counter on your screen. After each image you identify correctly, you’ll see that counter increase in 5 cup increments. TheGameWell pledges to donate the equivalent of 5 cups of water, for each correct answer, to citizens around the world in need of clean, safe and reliable sources of water. Each month, TheGameWell tabulates the aggregate amount of water that our players have accrued, and gives a commensurate financial donation to Charity Water, a US 501 (c)(3) public charity, who, for the past 14 years, has competed over 51,000 water projects in over 25 countries. They’ve ensured that over 11 million people around the world have access to clean water. Each month’s donation helps fund Charity Water as they build sustainable water solutions around the world.</p>

          <br />
          <h2>Why Water?</h2>
          <p>According to a 2019 report conducted by the World Health Organization, there are 785 million people in the world who live without clean water. That’s nearly 1 in 10 people worldwide. Or, twice the population of the United States. The majority live in isolated rural areas and spend hours every day walking to collect water for their family. Charity Water works with local experts and community members to find the best sustainable solution in each location where they work, whether it’s a well, a piped system, or a system for harvesting rainwater. And with every water point funded, Charity Water’s partners coordinate sanitation and hygiene training, and establish a local Water Committee to help keep water flowing for years to come.</p>

          <br />
          <h2>How are you funding this?</h2>
          <p>You’ll notice that we have a few links to Amazon on our page. Everytime you click on these links, or decide to change your Amazon bookmark to our custom link, a portion of your purchase will allow us to continue to donate money to fund water projects around the world.</p>

          <br />
          <h2>What kind of games can I play on the site?</h2>
          <p>Currently, we’re featuring eight different game modules: Magic The Gathering, Sports, Movies, Television, Cars, Dogs, Art and Album Covers. Despite a few filters that you can adjust on each module, the game play is essentially the same: See an image. See 4 choices. Identify the choice that matches the image. Repeat. In the future, we hope to add more game modules. We’d love to hear from you If you have a suggestion for a new one! Drop us a line at <a href="mailto:info@thegamewell.com">info@thegamewell.com</a> !</p>

          <br />
          <h2>I love TheGameWell! How can I help?</h2>
          <p>Right now, we’re learning from all our early players and supporters. Thanks so much for giving our game a try! You can help by emailing us at <a href="mailto:info@thegamewell.com">info@thegamewell.com</a> with any feedback on how to improve our game, and by spreading the word to those you know. The more players, the more water we can donate.</p>
          <br />
          <br />
        </TextPanel>
      </div>
    );
  };
}
  
export default withRouter(About);