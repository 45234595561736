import React from 'react';
import './auth.css';
import taglineDesktop from '../../assets/tagline-desktop.png';
import taglineMobile from '../../assets/tagline-mobile.png';
import { mobileBreakpoint } from '../../const';
import { withRouter, Link } from 'react-router-dom';
import userIcon from '../../assets/user-icon.png';
import ReactGA from 'react-ga';
import lockIcon from '../../assets/lock-icon.png';
import MobileExitIcon from '../../components/mobile-exit-icon/mobile-exit-icon';
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook-icon.svg';

export class Auth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      error: ""
    };
  }

  componentDidMount() {
    ReactGA.set({ page: "/auth" });
    ReactGA.pageview("/auth");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    this.props.setIsLoading(false);
  }

  handleLogin() {
    if(this.props.isSoundOn) this.props.buttonSound.play();
    if (this.state.username && this.state.password && !this.state.isSubmitting) {
      this.setState({ isSubmitting: true });
      fetch("/api/authenticate", {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          Username: this.state.username,
          Password: this.state.password
        })
      })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.props.setUser(result.user);
          window.location.replace("/");
        } else {
          this.setState({ error: result.error, isSubmitting: false });
        }
      })
      .catch(result => this.setState({ error: result.error ? result.error : "Incorrect username or password.", isSubmitting: false }));
    }
  }

  render() {
    return (
      <div>
        {
          this.props.windowSize.width > mobileBreakpoint ?
            <div className={ this.props.windowSize.width > mobileBreakpoint ? "tagline" : "tagline mobile" }>
              <img src={ this.props.windowSize.width > mobileBreakpoint ? taglineDesktop : taglineMobile } alt="Play with purpose - donate water with every correct answer!" />
            </div>
          : null
        }
        <div className={ `auth-container ${this.props.isMobile ? "auth-container-mobile" : ""}` } style={{ minHeight: `${this.props.minHeight*0.80}px` }}>
          <MobileExitIcon style={{ paddingRight: "1em", paddingTop: "1em" }} history={ this.props.history } />
          <div className="auth-content">
            <h1>User Profile</h1>
            <p>Join Our Leaderboard.</p>
            <hr />
            <div className="input">
              <div className="image-wrap" style={{ backgroundImage: `url(${userIcon})` }}></div>
              <input placeholder="Username or email" autoFocus value={ this.state.username } onChange={ e => this.setState({ username: e.target.value }) } autoComplet="username" />
            </div>
            <div className="input">
              <div className="image-wrap lock" style={{ backgroundImage: `url(${lockIcon})` }}></div>
              <input placeholder="Password" type="password" value={ this.state.password } onChange={ e => this.setState({ password: e.target.value }) } autoComplete="current-password" />
            </div>
            <p className="auth-error">{ this.state.error }</p>
            <Link to="/password-reset">Forgot password?</Link>
            <div className="log-in-button" onClick={ () => this.handleLogin() }>
              <p>Log in</p>
            </div>
            <p className="or-text">or</p>
            <div
              className="create-account-button"
              onClick={ () => {
                if(this.props.isSoundOn) this.props.buttonSound.play();
                this.props.history.push("/create-account");
              }}
            >
              <PlusIcon />
              <p>CREATE NEW ACCOUNT</p>
            </div>
            <div
              className="create-account-button"
              onClick={ () => {
                if(this.props.isSoundOn) this.props.buttonSound.play();
                window.location.replace("/auth/facebook");
              }}
            >
              <FacebookIcon />
              <p>FACEBOOK LOGIN</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(Auth);
