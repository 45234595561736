import React from 'react';
import './loading-overlay.css';
import logo from '../../assets/game-well-circle.png';

export const LoadingOverlay = props => {
  return(
    <div className={ `loading-overlay ${!props.isLoading ? "hide" : ""}` }>
      <div className="loader-container">
        <img src={ logo } alt="The Game Well Logo" />
        <p>PLAY WITH PURPOSE</p>
        <div className="loader"></div>
      </div>
    </div>
  );
}

export default LoadingOverlay;