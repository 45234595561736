import React from 'react';
import './tagline.css';
import taglineDesktop from '../../assets/tagline-desktop.png';
import taglineMobile from '../../assets/tagline-mobile.png';
import { mobileBreakpoint } from '../../const';

export const Tagline = props => {
  return(
    <div className={ props.windowSize.width > mobileBreakpoint ? "tagline" : "tagline mobile" } style={ props.style }>
      <img
        src={ props.windowSize.width > mobileBreakpoint ? taglineDesktop : taglineMobile }
        alt="Play with purpose - donate water with every correct answer!"
      />
    </div>
  );
};

export default Tagline;