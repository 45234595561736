import React from 'react';
import styles from './total-counter.module.css';
import totalCounterBg from '../../assets/total-counter-bg.png';
import SoundToggle from '../sound-toggle/sound-toggle';
import CountUp from 'react-countup';

export default class TotalCounter extends React.Component {
  render() {
    return(
      <div className={ styles.totalCounter } style={{ backgroundImage: `url(${totalCounterBg})` }}>
        <p><span className="emphased">{ this.props.yesterday ? <CountUp delay={2} duration={2} separator="," end={ this.props.yesterday } /> : 0 }</span> <span>cups of water donated yesterday.</span></p>
        <p><span className="emphased">{ this.props.allTime ? <CountUp delay={2} duration={2} separator="," end={ this.props.allTime } /> : 0 }</span> <span>cups donated all time.</span></p>
        <SoundToggle toggleSound={ this.props.toggleSound } isSoundOn={ this.props.isSoundOn } />
      </div>
    );
  }
}