import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import Home from './containers/home/home';
import About from './containers/about/about';
import PrivacyPolicy from './containers/privacy-policy/privacy-policy';
import TermsOfService from './containers/terms-of-service/terms-of-service';
import './App.css';
import overlayDesktop from './assets/overlay-desktop.png';
import overlayMobile from './assets/overlay-mobile.png';
import logoDesktop from './assets/logo-desktop.png';
import logoBg from './assets/logo-bg.png';
import { ReactComponent as HomeIcon } from './assets/home-icon.svg';
import bottle1 from './assets/water-bottle/Bottle1.png';
import bottle2 from './assets/water-bottle/Bottle2.png';
import bottle3 from './assets/water-bottle/Bottle3.png';
import bottle4 from './assets/water-bottle/Bottle4.png';
import bottle5 from './assets/water-bottle/Bottle5.png';
import bottle6 from './assets/water-bottle/Bottle6.png';
import bottle7 from './assets/water-bottle/Bottle7.png';
import { mobileBreakpoint } from './const';
import Mtg from './containers/mtg/mtg';
import Sports from './containers/sports/sports';
import Movies from './containers/movies/movies';
import Tv from './containers/tv/tv';
import Cookies from 'universal-cookie';
import { postData } from './components/helpers/helpers';
import { withRouter } from "react-router-dom";
import LoadingOverlay from './components/loading-overlay/loading-overlay';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import CookiesPopup from './components/cookies-popup/cookies-popup';
import ReactGA from 'react-ga';
import SoundToggle from './components/sound-toggle/sound-toggle';
import ButtonSound from './assets/button-sound.mp3';
import SoundOnIcon from './assets/sound-on.png';
import SoundOffIcon from './assets/sound-off.png';
import CountUp from 'react-countup';
import Dogs from './containers/dogs/dogs'
import Art from './containers/art/art'
import Cars from './containers/cars/cars'
import Albums from './containers/albums/albums'
import HowToPlay from './containers/how-to-play/how-to-play';
import Auth from './containers/auth/auth';
import CreateAccount from './containers/create-account/create-account';
import MyAccount from './containers/my-account/my-account';
import EditProfile from './containers/edit-profile/edit-profile';
import PasswordReset from './containers/password-reset/password-reset';
import Leaderboard from './containers/leaderboard/leaderboard';
import LeaderboardSmallDesktop from './components/leaderboard-small-desktop/leaderboard-small-desktop';

class App extends React.Component {
  appWrapElement;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      bgImageNo: 1,
      bgImage: require("./assets/backgrounds/1.jpg"),
      showHomeIcon: true,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
        initialHeight: null
      },
      waterDonated: 0,
      showLogo: true,
      showTotalCounter: true,
      isInGame: false,
      bottleImgNo: 0,
      userId: "",
      total: 0,
      yesterday: 0,
      showCookiesPopup: false,
      isSoundOn: true,
      buttonSound: new Audio(ButtonSound),
      numberOfQuestions: 0,
      correctAnswers: 0,
      user: null,
      isLinkCopied: false
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    ReactGA.initialize('UA-166149263-1');
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    
    if (!this.state.user) {
      fetch("/api/get-user")
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.setState({
            user: result.user,
            userId: result.user.id,
            showCookiesPopup: false
          });
        } else {
          this.getOrCreateDummyUser();
        }
      })
      .catch(() => this.getOrCreateDummyUser());
    }

    fetch('/api/totalcount')
      .then(response => response.json())
      .then(data => {
        this.setState({
          total: data.total * 5,
          yesterday: data.yesterday * 5,
          isLoading: false
        });
      });
  }

  getOrCreateDummyUser() {
    const cookies = new Cookies();
    if(cookies.get('UserId')) {
      this.setState({
        userId: cookies.get('UserId'),
        showCookiesPopup: !cookies.get('dismissed-cookies')
      });
    } else {
      postData('/api/createuser', {
        UserAgent: navigator.userAgent
      }).then(resp => {
        cookies.set("UserId", resp.userId, { path: '/' });
        this.setState({
          userId: resp.userId,
          showCookiesPopup: !cookies.get('dismissed-cookies')
        });
      });
    }
  }

  updateWindowDimensions() {
    this.setState({
      window: {
        width: window.innerWidth,
        height: this.appWrapElement.clientHeight,
        initialHeight: this.state.window.initialHeight ? this.state.window.initialHeight : this.appWrapElement.clientHeight
      }
    });
  }

  changeBgImage() {
    const nextBgImage = new Image();

    if (this.state.bgImageNo === 17) {
      this.setState({ 
        bgImageNo: 1,
        bgImage: require("./assets/backgrounds/1.jpg")
      });
    } else {
      nextBgImage.addEventListener('load', () => {
        this.setState({ 
          bgImageNo: this.state.bgImageNo + 1,
          bgImage: nextBgImage.src
        });
      });
      nextBgImage.src = require(`./assets/backgrounds/${this.state.bgImageNo + 1}.jpg`);
    }
  }

  setIsLoading(value) {
    this.setState({ isLoading: value });
  }

  hideHomeIcon() {
    this.setState({ showHomeIcon: false });
  }
  showHomeIcon() {
    this.setState({ showHomeIcon: true });
  }

  hideLogo() {
    this.setState({ showLogo: false });
  }
  showLogo() {
    this.setState({ showLogo: true });
  }

  hideTotalCounter() {
    this.setState({ showTotalCounter: false });
  }
  showTotalCounter() {
    this.setState({ showTotalCounter: true });
  }

  setInGame(value) {
    this.setState({ isInGame: value });
  }

  addWaterDonated(amount, isFromShare) {
    if((this.state.numberOfQuestions + 1) % 5 === 0) this.changeBgImage();
    let waterDonated = this.state.waterDonated + amount;
    while(waterDonated > 30) {
      waterDonated -= 30;
    }
    const imgNo = waterDonated / 5;

    this.setState({
      waterDonated: this.state.waterDonated + amount,
      bottleImgNo: imgNo,
      numberOfQuestions: isFromShare === true ? this.state.numberOfQuestions : this.state.numberOfQuestions + 1,
      correctAnswers: isFromShare === true ? this.state.correctAnswers : this.state.correctAnswers + 1
    });
    if (this.state.user) {
      this.setState({
        user: { ...this.state.user, totalDonated: (this.state.user.totalDonated || 0) + amount }
      });
    }
  }

  addQuestionAnswered() {
    if((this.state.numberOfQuestions + 1) % 5 === 0) this.changeBgImage();
    this.setState({ numberOfQuestions: this.state.numberOfQuestions + 1 });
  }

  toggleSound() {
    if (this.state.isSoundOn) {
      this.setState({ isSoundOn: false });
    } else {
      this.state.buttonSound.play();
      this.setState({ isSoundOn: true });
    }
  }

  dismissCookiesPopup() {
    const cookies = new Cookies();
    cookies.set("dismissed-cookies", true, { path: '/' });
    this.setState({ showCookiesPopup: false });
  }

  render() {
    return (
      <div
        className={`app bg${this.state.bgImageNo}`}
        style={{ backgroundImage: `url(${this.state.bgImage})` }}
        ref={ (appWrapElement) => { this.appWrapElement = appWrapElement } }
      >
        <LoadingOverlay isLoading={ this.state.isLoading } logoImg={ logoDesktop } />

        { this.state.showCookiesPopup ? <CookiesPopup isMobile={ !(this.state.window.width > mobileBreakpoint) } onDismiss={ () => this.dismissCookiesPopup() } /> : null }

        <div
          className={ this.state.window.width > mobileBreakpoint ? " overlay desktop" : " overlay mobile" }
          style={{ backgroundImage: this.state.window.width > mobileBreakpoint ? `url(${overlayDesktop})` : `url(${overlayMobile})` }}
        >
          
          {
            this.state.showLogo ?
              this.state.window.width > mobileBreakpoint ?
                <div className="logo" style={{ backgroundImage: `url(${logoBg})` }}>
                  <img src={ logoDesktop } alt="logo" onClick={ () => this.props.history.push("/") } />
                </div>
              : <div className="logo">
                  <img src={ logoDesktop } alt="logo" style={{ width: "40vw", maxWidth: "200px" }} />
                </div>
            : null
          }

          {
            /*this.state.window.width > mobileBreakpoint ?
            <>
              <div className="sidebar-left">
                <div
                  className="signin-button"
                  onClick={ () => {
                    this.props.history.push(`${this.state.user ? "/my-account" : "/auth"}`);
                    if (this.state.isSoundOn) this.state.buttonSound.play();
                  }}
                >
                  <img src={ userIcon } alt="user icon" />
                  {
                    this.state.user ?
                      <div className="user-info-wrap">
                        <p>Welcome, { this.state.user.username }</p>
                        <p className="cups-donated">CUPS DONATED: { this.state.user.totalDonated }</p>
                        <p>RANKING: { this.state.user.ranking }</p>
                      </div>
                    :
                      <p>SIGN IN / SIGN UP</p>
                  }
                </div>
                <div className="add-wrap" onClick={() => {
                  ReactGA.event({
                    category: "General",
                    action: 'Desktop Ad Left Click'
                  });
                  window.open("https://www.amazon.com/?tag=thegamewell-20", "_blank");
                }}>
                  <p>Ad</p>
                  <img src={AmazonAdLogo} alt="Amazon Ad Logo" />
                  <p><br/>There's something<br />NEW to shop for!</p>
                  <img className="amazon-ad" src={AmazonAd} alt="Amazon Ad" />
                  <p><br/>Click to support<br />The Game Well™</p>
                </div>
              </div>
              <div className="sidebar-right">
                <div className="add-wrap">
                  <img className="ad-star-mark" src={StarMark} alt="Star Mark" />
                  <img className="amazon-a-logo" src={AmazonALogo} alt="Amazon A Logo" />
                  <p>You shop.<br />Amazon gives.</p>
                  <hr />
                  <p>Donate <span class="orange">for free</span><br />with your<br />everyday purchases!</p>
                  <p className="amzn-bookmark">AMAZON BOOKMARK</p>
                  <p className="small">click below to copy link:</p>
                  <div className="link-copy-button" onClick={() => {
                    ReactGA.event({
                      category: "General",
                      action: 'Desktop Ad Right Link Copy'
                    });
                    this.setState({ isLinkCopied: true });
                    this.textArea.select();
                    document.execCommand('copy');
                  }}>
                    { !this.state.isLinkCopied ? <p>amazon.com/?tag=thegamewell-20</p> : <p>Copied!</p> }
                  </div>
                  <img className="bookmark-img" src={BookmarkImg} alt="Bookmark Instructions" />
                  <textarea ref={(textarea) => this.textArea = textarea} type="text" value="https://www.amazon.com/?tag=thegamewell-20" />
                </div>
              </div>
            </>
            : null*/
          }

          { 
            this.state.showHomeIcon && this.state.window.width > mobileBreakpoint ? 
              <>
                <div
                  className="main-menu-icon"
                  onClick={ 
                    () => { 
                      this.props.history.push("/");
                      if (this.state.isSoundOn) this.state.buttonSound.play();
                    }
                  }
                >
                  <p>main menu</p>
                  <HomeIcon />
                </div>
                <SoundToggle
                  toggleSound={ () => this.toggleSound() }
                  isSoundOn={ this.state.isSoundOn }
                  style={{ position: "absolute", top: "5em", right: "3.2vw" }}
                />
              </>
            : null 
          }

          {
            !(this.state.window.width > mobileBreakpoint) ?
            <div
              className="sound-toggle-mobile"
              onClick={ () => this.toggleSound() }
              style={{
                top:
                  this.props.location.pathname === "/"
                  || this.props.location.pathname === "/about"
                  || this.props.location.pathname === "/privacy-policy"
                  || this.props.location.pathname === "/terms-of-service"
                  || this.props.location.pathname === "/how-to-play"
                ? "1%" : "10vh"
              }}
            >
              {
                this.state.isSoundOn ? <img src={SoundOnIcon} alt="Sound On" /> : <img src={SoundOffIcon} alt="Sound Off" />
              }
            </div>
            :
            null
          }

          <TransitionGroup>
            <CSSTransition
              key={this.props.location.key}
              timeout={500}
              classNames="page"
              unmountOnExit
            >
              <Switch location={this.props.location}>
                <Route
                  path='/mtg'
                  render={() =>
                    <Mtg
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      addWaterDonated={ (amount, isFromShare) => this.addWaterDonated(amount, isFromShare) }
                      hideLogo={ () => this.hideLogo() }
                      showLogo={ () => this.showLogo() }
                      setInGame= { value => this.setInGame(value) }
                      bottleImgNo={ this.state.bottleImgNo }
                      waterDonatedAmount={ this.state.waterDonated }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      userId={ this.state.userId }
                      history={ this.props.history }
                      setIsLoading={ value => this.setIsLoading(value) }
                      buttonSound={ this.state.buttonSound }
                      isSoundOn={ this.state.isSoundOn }
                      addQuestionAnswered={ () => this.addQuestionAnswered() }
                      correctAnswers={ this.state.correctAnswers }
                      numberOfQuestions={ this.state.numberOfQuestions }
                    />
                  } 
                />
                <Route
                  path='/sports'
                  render={() =>
                    <Sports
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      addWaterDonated={ (amount, isFromShare) => this.addWaterDonated(amount, isFromShare) }
                      hideLogo={ () => this.hideLogo() }
                      showLogo={ () => this.showLogo() }
                      setInGame= { value => this.setInGame(value) }
                      bottleImgNo={ this.state.bottleImgNo }
                      waterDonatedAmount={ this.state.waterDonated }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      userId={ this.state.userId }
                      history={ this.props.history }
                      setIsLoading={ value => this.setIsLoading(value) }
                      buttonSound={ this.state.buttonSound }
                      isSoundOn={ this.state.isSoundOn }
                      addQuestionAnswered={ () => this.addQuestionAnswered() }
                      correctAnswers={ this.state.correctAnswers }
                      numberOfQuestions={ this.state.numberOfQuestions }
                    />
                  } 
                />
                <Route
                  path='/movies'
                  render={() =>
                    <Movies
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      addWaterDonated={ (amount, isFromShare) => this.addWaterDonated(amount, isFromShare) }
                      hideLogo={ () => this.hideLogo() }
                      showLogo={ () => this.showLogo() }
                      setInGame= { value => this.setInGame(value) }
                      bottleImgNo={ this.state.bottleImgNo }
                      waterDonatedAmount={ this.state.waterDonated }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      userId={ this.state.userId }
                      history={ this.props.history }
                      setIsLoading={ value => this.setIsLoading(value) }
                      buttonSound={ this.state.buttonSound }
                      isSoundOn={ this.state.isSoundOn }
                      addQuestionAnswered={ () => this.addQuestionAnswered() }
                      correctAnswers={ this.state.correctAnswers }
                      numberOfQuestions={ this.state.numberOfQuestions }
                    />
                  } 
                />
                <Route
                  path='/tv'
                  render={() =>
                    <Tv
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      addWaterDonated={ (amount, isFromShare) => this.addWaterDonated(amount, isFromShare) }
                      hideLogo={ () => this.hideLogo() }
                      showLogo={ () => this.showLogo() }
                      setInGame= { value => this.setInGame(value) }
                      bottleImgNo={ this.state.bottleImgNo }
                      waterDonatedAmount={ this.state.waterDonated }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      userId={ this.state.userId }
                      history={ this.props.history }
                      setIsLoading={ value => this.setIsLoading(value) }
                      buttonSound={ this.state.buttonSound }
                      isSoundOn={ this.state.isSoundOn }
                      addQuestionAnswered={ () => this.addQuestionAnswered() }
                      correctAnswers={ this.state.correctAnswers }
                      numberOfQuestions={ this.state.numberOfQuestions }
                    />
                  } 
                />
                <Route
                  path='/dogs'
                  render={() =>
                    <Dogs
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      addWaterDonated={ (amount, isFromShare) => this.addWaterDonated(amount, isFromShare) }
                      hideLogo={ () => this.hideLogo() }
                      showLogo={ () => this.showLogo() }
                      setInGame= { value => this.setInGame(value) }
                      bottleImgNo={ this.state.bottleImgNo }
                      waterDonatedAmount={ this.state.waterDonated }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      userId={ this.state.userId }
                      history={ this.props.history }
                      setIsLoading={ value => this.setIsLoading(value) }
                      buttonSound={ this.state.buttonSound }
                      isSoundOn={ this.state.isSoundOn }
                      addQuestionAnswered={ () => this.addQuestionAnswered() }
                      correctAnswers={ this.state.correctAnswers }
                      numberOfQuestions={ this.state.numberOfQuestions }
                    />
                  } 
                />
                <Route
                  path='/art'
                  render={() =>
                    <Art
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      addWaterDonated={ (amount, isFromShare) => this.addWaterDonated(amount, isFromShare) }
                      hideLogo={ () => this.hideLogo() }
                      showLogo={ () => this.showLogo() }
                      setInGame= { value => this.setInGame(value) }
                      bottleImgNo={ this.state.bottleImgNo }
                      waterDonatedAmount={ this.state.waterDonated }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      userId={ this.state.userId }
                      history={ this.props.history }
                      setIsLoading={ value => this.setIsLoading(value) }
                      buttonSound={ this.state.buttonSound }
                      isSoundOn={ this.state.isSoundOn }
                      addQuestionAnswered={ () => this.addQuestionAnswered() }
                      correctAnswers={ this.state.correctAnswers }
                      numberOfQuestions={ this.state.numberOfQuestions }
                    />
                  } 
                />
                <Route
                  path='/cars'
                  render={() =>
                    <Cars
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      addWaterDonated={ (amount, isFromShare) => this.addWaterDonated(amount, isFromShare) }
                      hideLogo={ () => this.hideLogo() }
                      showLogo={ () => this.showLogo() }
                      setInGame= { value => this.setInGame(value) }
                      bottleImgNo={ this.state.bottleImgNo }
                      waterDonatedAmount={ this.state.waterDonated }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      userId={ this.state.userId }
                      history={ this.props.history }
                      setIsLoading={ value => this.setIsLoading(value) }
                      buttonSound={ this.state.buttonSound }
                      isSoundOn={ this.state.isSoundOn }
                      addQuestionAnswered={ () => this.addQuestionAnswered() }
                      correctAnswers={ this.state.correctAnswers }
                      numberOfQuestions={ this.state.numberOfQuestions }
                    />
                  } 
                />
                <Route
                  path='/albums'
                  render={() =>
                    <Albums
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      addWaterDonated={ (amount, isFromShare) => this.addWaterDonated(amount, isFromShare) }
                      hideLogo={ () => this.hideLogo() }
                      showLogo={ () => this.showLogo() }
                      setInGame= { value => this.setInGame(value) }
                      bottleImgNo={ this.state.bottleImgNo }
                      waterDonatedAmount={ this.state.waterDonated }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      userId={ this.state.userId }
                      history={ this.props.history }
                      setIsLoading={ value => this.setIsLoading(value) }
                      buttonSound={ this.state.buttonSound }
                      isSoundOn={ this.state.isSoundOn }
                      addQuestionAnswered={ () => this.addQuestionAnswered() }
                      correctAnswers={ this.state.correctAnswers }
                      numberOfQuestions={ this.state.numberOfQuestions }
                    />
                  } 
                />
                <Route
                  path='/terms-of-service'
                  render={() => 
                    <TermsOfService
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      setIsLoading={ value => this.setIsLoading(value) }
                    /> 
                  }
                />
                <Route
                  path='/privacy-policy'
                  render={() => 
                    <PrivacyPolicy
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      setIsLoading={ value => this.setIsLoading(value) }
                    /> 
                  }
                />
                <Route
                  path='/about'
                  render={() =>
                    <About
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      setIsLoading={ value => this.setIsLoading(value) }
                    />
                  }
                />
                <Route
                  path='/how-to-play'
                  render={() =>
                    <HowToPlay
                      windowSize={ this.state.window }
                      showHomeIcon={ () => this.showHomeIcon() }
                      hideTotalCounter={ () => this.hideTotalCounter() }
                      setIsLoading={ value => this.setIsLoading(value) }
                    />
                  }
                />
                <Route
                  path='/auth'
                  render={() => {
                    if (this.state.window.width > mobileBreakpoint) {
                      return(
                        <Auth
                          windowSize={ this.state.window }
                          showHomeIcon={ () => this.showHomeIcon() }
                          hideTotalCounter={ () => this.hideTotalCounter() }
                          setIsLoading={ value => this.setIsLoading(value) }
                          setUser={ user => this.setState({ user: user }) }
                          buttonSound={ this.state.buttonSound }
                          isSoundOn={ this.state.isSoundOn }
                        />
                      );
                    } else {
                      return (
                        <div className="cookies-popup-bg">
                          <Auth
                            windowSize={ this.state.window }
                            showHomeIcon={ () => this.showHomeIcon() }
                            hideTotalCounter={ () => this.hideTotalCounter() }
                            setIsLoading={ value => this.setIsLoading(value) }
                            setUser={ user => this.setState({ user: user }) }
                            buttonSound={ this.state.buttonSound }
                            isSoundOn={ this.state.isSoundOn }
                            isMobile={ true }
                            minHeight={ this.state.window.initialHeight }
                          />
                        </div>
                      );
                    }
                  }}
                />
                <Route
                  path='/create-account'
                  render={() => {
                    if (this.state.window.width > mobileBreakpoint) {
                      return (
                        <CreateAccount
                          windowSize={ this.state.window }
                          showHomeIcon={ () => this.showHomeIcon() }
                          hideTotalCounter={ () => this.hideTotalCounter() }
                          setIsLoading={ value => this.setIsLoading(value) }
                          setUser={ user => this.setState({ user: user }) }
                          buttonSound={ this.state.buttonSound }
                          isSoundOn={ this.state.isSoundOn }
                        />
                      );
                    } else {
                      return (
                        <div className="cookies-popup-bg">
                          <CreateAccount
                            windowSize={ this.state.window }
                            showHomeIcon={ () => this.showHomeIcon() }
                            hideTotalCounter={ () => this.hideTotalCounter() }
                            setIsLoading={ value => this.setIsLoading(value) }
                            setUser={ user => this.setState({ user: user }) }
                            buttonSound={ this.state.buttonSound }
                            isSoundOn={ this.state.isSoundOn }
                            minHeight={ this.state.window.initialHeight }
                            isMobile={ true }
                          />
                        </div>
                      );
                    }
                  }
                  }
                />
                <Route
                  path='/my-account'
                  render={() => {
                    if (this.state.window.width > mobileBreakpoint) {
                      return(
                        <MyAccount
                          windowSize={ this.state.window }
                          showHomeIcon={ () => this.showHomeIcon() }
                          hideTotalCounter={ () => this.hideTotalCounter() }
                          setIsLoading={ value => this.setIsLoading(value) }
                          setUser={ user => this.setState({ user: user }) }
                          buttonSound={ this.state.buttonSound }
                          isSoundOn={ this.state.isSoundOn }
                          user={ this.state.user }
                        />
                      );
                    } else {
                      return (
                        <div className="cookies-popup-bg">
                          <MyAccount
                            windowSize={ this.state.window }
                            showHomeIcon={ () => this.showHomeIcon() }
                            hideTotalCounter={ () => this.hideTotalCounter() }
                            setIsLoading={ value => this.setIsLoading(value) }
                            setUser={ user => this.setState({ user: user }) }
                            buttonSound={ this.state.buttonSound }
                            isSoundOn={ this.state.isSoundOn }
                            isMobile={ true }
                            minHeight={ this.state.window.initialHeight }
                            user={ this.state.user }
                          />
                        </div>
                      );
                    }
                  }}
                />
                <Route
                  path='/edit-profile'
                  render={() => {
                    if (this.state.window.width > mobileBreakpoint) {
                      return(
                        <EditProfile
                          windowSize={ this.state.window }
                          showHomeIcon={ () => this.showHomeIcon() }
                          hideTotalCounter={ () => this.hideTotalCounter() }
                          setIsLoading={ value => this.setIsLoading(value) }
                          setUser={ user => this.setState({ user: user }) }
                          buttonSound={ this.state.buttonSound }
                          isSoundOn={ this.state.isSoundOn }
                          user={ this.state.user }
                        />
                      );
                    } else {
                      return (
                        <div className="cookies-popup-bg">
                          <EditProfile
                            windowSize={ this.state.window }
                            showHomeIcon={ () => this.showHomeIcon() }
                            hideTotalCounter={ () => this.hideTotalCounter() }
                            setIsLoading={ value => this.setIsLoading(value) }
                            setUser={ user => this.setState({ user: user }) }
                            buttonSound={ this.state.buttonSound }
                            isSoundOn={ this.state.isSoundOn }
                            isMobile={ true }
                            minHeight={ this.state.window.initialHeight }
                            user={ this.state.user }
                          />
                        </div>
                      );
                    }
                  }}
                />
                <Route
                  path='/password-reset'
                  render={() => {
                    if (this.state.window.width > mobileBreakpoint) {
                      return(
                        <PasswordReset
                          windowSize={ this.state.window }
                          showHomeIcon={ () => this.showHomeIcon() }
                          hideTotalCounter={ () => this.hideTotalCounter() }
                          setIsLoading={ value => this.setIsLoading(value) }
                          setUser={ user => this.setState({ user: user }) }
                          buttonSound={ this.state.buttonSound }
                          isSoundOn={ this.state.isSoundOn }
                        />
                      );
                    } else {
                      return (
                        <div className="cookies-popup-bg">
                          <PasswordReset
                            windowSize={ this.state.window }
                            showHomeIcon={ () => this.showHomeIcon() }
                            hideTotalCounter={ () => this.hideTotalCounter() }
                            setIsLoading={ value => this.setIsLoading(value) }
                            setUser={ user => this.setState({ user: user }) }
                            buttonSound={ this.state.buttonSound }
                            isSoundOn={ this.state.isSoundOn }
                            isMobile={ true }
                            minHeight={ this.state.window.initialHeight }
                          />
                        </div>
                      );
                    }
                  }}
                />
                <Route
                  path='/leaderboard'
                  render={() => {
                    if (this.state.window.width > mobileBreakpoint) {
                      return(
                        <Leaderboard
                          windowSize={ this.state.window }
                          showHomeIcon={ () => this.showHomeIcon() }
                          hideTotalCounter={ () => this.hideTotalCounter() }
                          setIsLoading={ value => this.setIsLoading(value) }
                          setUser={ user => this.setState({ user: user }) }
                          buttonSound={ this.state.buttonSound }
                          isSoundOn={ this.state.isSoundOn }
                          user={ this.state.user }
                        />
                      );
                    } else {
                      return (
                        <div className="cookies-popup-bg">
                          <Leaderboard
                            windowSize={ this.state.window }
                            showHomeIcon={ () => this.showHomeIcon() }
                            hideTotalCounter={ () => this.hideTotalCounter() }
                            setIsLoading={ value => this.setIsLoading(value) }
                            setUser={ user => this.setState({ user: user }) }
                            buttonSound={ this.state.buttonSound }
                            isSoundOn={ this.state.isSoundOn }
                            isMobile={ true }
                            minHeight={ this.state.window.initialHeight }
                            user={ this.state.user }
                          />
                        </div>
                      );
                    }
                  }}
                />
                <Route
                  path='/'
                  render={() =>
                    <Home
                      total={ this.state.total }
                      yesterday={ this.state.yesterday }
                      windowSize={ this.state.window }
                      hideHomeIcon={ () => this.hideHomeIcon() }
                      setIsLoading={ value => this.setIsLoading(value) }
                      toggleSound={ () => this.toggleSound() }
                      isSoundOn={ this.state.isSoundOn }
                      buttonSound={ this.state.buttonSound }
                      showTotalCounter={ () => this.showTotalCounter() }
                      user={ this.state.user }
                    />
                  } 
                />
              </Switch>
            </CSSTransition>
          </TransitionGroup>

          {this.state.window.width > mobileBreakpoint ? 
            <div>
              <div className="footer">
                { this.props.location.pathname === "/" ? <LeaderboardSmallDesktop isSoundOn={ this.state.isSoundOn } buttonSound={ this.state.buttonSound } /> : null }
                <div className="upper">
                  <div>
                    <p><b>Donate 5 cups of water</b> for every correct answer.</p>
                  </div>
                  <div className="learn-more">
                    <Link to='/about'>LEARN MORE HERE</Link>
                  </div>
                </div>
                <div className="bottom">
                  <Link to='/how-to-play'>HOW&nbsp;TO&nbsp;PLAY</Link>
                  <Link to='/privacy-policy'>PRIVACY&nbsp;POLICY</Link>
                  <Link to='/terms-of-service'>TERMS&nbsp;OF&nbsp;SERVICE</Link>
                  <a href="mailto:info@thegamewell.com">CONTACT</a>
                </div>
              </div>
              <div className="water-counter">
                <div className="counter-wrap">
                  <p className="percentage">{ this.state.correctAnswers }/{ this.state.numberOfQuestions } Correct - { this.state.numberOfQuestions !== 0 ? Math.round(this.state.correctAnswers/this.state.numberOfQuestions * 100) : 0 }%</p>
                  <div className="counter">
                    <p>{ this.state.waterDonated }</p><p style={{ fontSize: "2.5vh" }}>Cups</p>
                  </div>
                  <p className="donated">DONATED!</p>
                </div>
                <div className="bottle">
                  <img
                    src={
                      this.state.bottleImgNo === 0 ? bottle1
                      : this.state.bottleImgNo === 1 ? bottle2
                      : this.state.bottleImgNo === 2 ? bottle3
                      : this.state.bottleImgNo === 3 ? bottle4
                      : this.state.bottleImgNo === 4 ? bottle5
                      : this.state.bottleImgNo === 5 ? bottle6
                      : this.state.bottleImgNo === 6 ? bottle7
                      : null
                    }
                    alt="Bottle" />
                  { this.state.bottleImgNo === 6 ? <img className="confetti" src={ require("./assets/water-bottle/ConfettiAnimation.gif") } alt="confetti animation" /> : null }
                </div>
              </div>
            </div>
            :
            <div>
              {
                !this.state.isInGame ?
                <div
                  className="counter-bottle-mobile"
                  style={{ bottom: this.state.showTotalCounter ? "16.5vh" : "8vh" }}
                >
                  <div className="bottle-mobile">
                  <img
                      src={
                        this.state.bottleImgNo === 0 ? bottle1
                        : this.state.bottleImgNo === 1 ? bottle2
                        : this.state.bottleImgNo === 2 ? bottle3
                        : this.state.bottleImgNo === 3 ? bottle4
                        : this.state.bottleImgNo === 4 ? bottle5
                        : this.state.bottleImgNo === 5 ? bottle6
                        : this.state.bottleImgNo === 6 ? bottle7
                        : null
                      }
                      alt="Bottle" />
                  </div>
                  <div className="counter-wrap-mobile">
                    <p className="percentage">{ this.state.correctAnswers }/{ this.state.numberOfQuestions } Correct - { this.state.numberOfQuestions !== 0 ? Math.round(this.state.correctAnswers/this.state.numberOfQuestions * 100) : 0 }%</p>
                    <div className="counter-mobile">
                      <p>{ this.state.waterDonated }</p><p style={{ fontSize: "3vh", marginLeft: "2vw" }}>Cups</p>
                    </div>
                    <p className={ `donated-mobile ${this.state.isInGame ? "in-game" : ""}` }>DONATED!</p>
                  </div>
                </div>
                : null
              }
              {
                !this.state.isInGame && this.state.showTotalCounter ?
                  <div className="total-counter">
                    <p><span className="emphased"><CountUp delay={2} duration={2} separator="," end={ this.state.yesterday } /></span> <span>cups of water donated yesterday.</span></p>
                    <p><span className="emphased"><CountUp delay={2} duration={2} separator="," end={ this.state.total } /></span> <span>cups donated all time.</span></p>
                  </div>
                : null
              }
              <div className="footer-mobile">
                <Link style={{ fontSize: "2vh", marginRight: "3vw", marginLeft: "3vw" }} to='/how-to-play'>HOW TO PLAY</Link>
                <Link style={{ fontSize: "2vh", marginRight: "3vw", marginLeft: "3vw" }} to='/about'>ABOUT</Link>
                <br />
                <Link to='/privacy-policy'>PRIVACY POLICY</Link>
                <Link to='/terms-of-service'>TERMS OF SERVICE</Link>
                <a href="mailto:info@thegamewell.com">CONTACT</a>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
};

export default withRouter(App);
