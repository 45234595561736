import React from 'react';
import taglineDesktop from '../../assets/tagline-desktop.png';
import taglineMobile from '../../assets/tagline-mobile.png';
import TextPanel from '../../components/text-panel/text-panel';
import { mobileBreakpoint } from '../../const';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

export class HowToPlay extends React.Component {
  componentDidMount() {
    ReactGA.set({ page: "/how-to-play" });
    ReactGA.pageview("/how-to-play");
    this.props.showHomeIcon();
    this.props.hideTotalCounter();
    this.props.setIsLoading(false);
  }

  render() {
    return (
      <div>
        {
          this.props.windowSize.width > mobileBreakpoint ?
            <div className={ this.props.windowSize.width > mobileBreakpoint ? "tagline" : "tagline mobile" }>
              <img src={ this.props.windowSize.width > mobileBreakpoint ? taglineDesktop : taglineMobile } alt="Play with purpose - donate water with every correct answer!" />
            </div>
          : null
        }
        <TextPanel
          title="how to play"
          isMobile={ this.props.windowSize.width < mobileBreakpoint }
          history={ this.props.history }
        >
          <div style={{ textAlign: "center", fontSize: "1.3em" }}>
            <br />
            <p style={{ fontWeight: 500 }}>All of our games follow the same premise. You are shown an image and 4 choices. Choose the
            choice that best matches the image. Answer correctly, and TheGameWell donates the equivalent
            of 5 cups of water to those in need.</p>
            <p style={{ fontWeight: 500 }}>Certain games allow you to customize your experience. For
            example, our “sports” game allows you to select which sports (baseball, basketball, football,
            hockey) you want included, as well as the year range of the athletes presented. Our movie game
            allows you to select the year range of the movies you want included. Other games, such as dog
            breeds, don’t feature these customized options.</p>
            <p style={{ fontWeight: 500 }}>We hope you enjoy playing!</p>
            <br />
            <br />
          </div>
        </TextPanel>
      </div>
    );
  };
}

export default withRouter(HowToPlay);
