export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

export function parseCvsAsync(file) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', file, true);
    xhr.send();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        let headers;
        const parsed = [];
        
        xhr.responseText.split(/\r\n|\n|\r/).forEach((line, index) => {
          if(index === 0){
            headers = line.split(";");
          } else {
            const objectToPush = {};
            line.split(";").forEach((entry, i) => {
              objectToPush[headers[i]] = entry;
            });
            parsed.push(objectToPush);
          }
        });

        resolve(parsed);
      }
    };
  });
};

export async function postData(url = '', data = {}) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
    return response.json();
  } catch { }
};

export function toLowerCaseExceptFirst(string) {
  return string.replace(/\S*/g, function (word) {
      return word.charAt(0) + word.slice(1).toLowerCase();
  });
}